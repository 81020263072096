export const tuningData = [
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 21l18 0"></path>
            <path d="M5 21v-14l8 -4v18"></path>
            <path d="M19 21v-10l-6 -4"></path>
            <path d="M9 9l0 .01"></path>
            <path d="M9 12l0 .01"></path>
            <path d="M9 15l0 .01"></path>
            <path d="M9 18l0 .01"></path>
        </svg>,
        title:'Common Settings',
        text:'Description text here...',
        path:'common-settings',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5"></path>
            <path d="M3 10h18"></path>
            <path d="M16 19h6"></path>
            <path d="M19 16l3 3l-3 3"></path>
            <path d="M7.005 15h.005"></path>
            <path d="M11 15h2"></path>
        </svg>,
        title:'Payment Settings',
        text:'Description text here...',
        path:'payment-set',
        theme_color:'var(--theme-color2)'
    },
    {
        icon: 
        <svg className="mb-3" xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
            <path d="M9 7l1 0"></path>
            <path d="M9 13l6 0"></path>
            <path d="M13 17l2 0"></path>
        </svg>,
        title:'Account Settings',
        text:'Description text here...',
        path:'accounts-setting',
        theme_color:'var(--theme-color3)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24"  viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
            <path d="M7 8v1" />
            <path d="M17 8v1" />
            <path d="M12.5 4c-.654 1.486 -1.26 3.443 -1.5 9h2.5c-.19 2.867 .094 5.024 .5 7" />
            <path d="M7 15.5c3.667 2 6.333 2 10 0" />
        </svg>,
        title:'Role Managment',
        text:'Description text here...',
        path:'role-add',
        theme_color:'var(--theme-color5)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M13 5h8"></path>
            <path d="M13 9h5"></path>
            <path d="M13 15h8"></path>
            <path d="M13 19h5"></path>
            <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
            <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
        </svg>,
        title:'Apps',
        text:'Description text here...',
        path:'app',
        theme_color:'var(--theme-color6)'
    },
    {
        icon: 
        <svg className="mb-3" xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1"></path>
        </svg>,
        title:'Documentation',
        text:'Description text here...',
        path:'docs',
        theme_color:'var(--theme-color4)'
    }, 
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'Changelog',
        text:'Description text here...',
        path:'changelog',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'About US',
        text:'Description text here...',
        path:'about-us',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'Contact US',
        text:'Description text here...',
        path:'contact-us',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'Privacy Policy',
        text:'Description text here...',
        path:'privacy-policy',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697"></path><path d="M18 14v4h4"></path><path d="M18 11v-4a2 2 0 0 0 -2 -2h-2"></path><path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path><path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path><path d="M8 11h4"></path><path d="M8 15h3"></path></svg>,
        title:'Terms Conditions',
        text:'Description text here...',
        path:'terms-conditions',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" className="mb-3"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path><path d="M21 21l-6 -6"></path></svg>,
        title:'Search',
        text:'Description text here...',
        path:'search',
        theme_color:'var(--theme-color1)'
    }
]