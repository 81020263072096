export const hrmsData = [
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 13a3 3 0 1 0 0 -6a3 3 0 0 0 0 6z" />
            <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
            <path d="M6 20.05v-.05a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v.05" />
        </svg>,
        title:'Employees Add',
        text:'Description text here...',
        path:'employees-add',
        theme_color:'var(--theme-color4)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M6 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
            <path d="M9 6v-1a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v1" />
            <path d="M6 10h12" />
            <path d="M6 16h12" />
            <path d="M9 20v1" />
            <path d="M15 20v1" />
        </svg>,
        title:'Leave Tracking',
        text:'Description text here...',
        path:'employees-leave',
        theme_color:'var(--theme-color6)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 12a9 9 0 0 0 5.998 8.485m12.002 -8.485a9 9 0 1 0 -18 0"></path>
            <path d="M12 7v5"></path><path d="M12 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2"></path>
            <path d="M18 15v2a1 1 0 0 0 1 1h1"></path>
            <path d="M21 15v6"></path>
        </svg>,
        title:'Duty Changes',
        text:'Description text here...',
        path:'duty-assigned-list',
        theme_color:'var(--theme-color2)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24"  viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3" />
            <path d="M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6" />
            <path d="M12 11v2a14 14 0 0 0 2.5 8" />
            <path d="M8 15a18 18 0 0 0 1.8 6" />
            <path d="M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95" />
        </svg>,
        title:'Attendance',
        text:'Description text here...',
        path:'employees-attendance',
        theme_color:'var(--theme-color3)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M7 9m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
            <path d="M14 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
        </svg>,
        title:'Salary',
        text:'Description text here...',
        path:'payroll-employee-salary',
        theme_color:'var(--theme-color5)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'Reports',
        text:'Description text here...',
        path:'payroll-report',
        theme_color:'var(--theme-color1)'
    }, 
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
            <path d="M17 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
            <path d="M7 5l7 0" />
            <path d="M10 19l7 0" />
            <path d="M9 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M15 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M7 5.5a5 6.5 0 0 1 5 6.5a5 6.5 0 0 0 5 6.5" />
        </svg>,
        title:'Departments',
        text:'Description text here...',
        path:'employees-departments',
        theme_color:'var(--theme-color4)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" className="mb-3"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 21l18 0"></path><path d="M3 10l18 0"></path><path d="M5 6l7 -3l7 3"></path><path d="M4 10l0 11"></path><path d="M20 10l0 11"></path><path d="M8 14l0 3"></path><path d="M12 14l0 3"></path><path d="M16 14l0 3"></path></svg>,
        title:'Employees Loan',
        text:'Description text here...',
        path:'employees-loan',
        theme_color:'var(--theme-color5)'
    },
]