import React from 'react'
import { Link } from 'react-router-dom'

const NoData = () => {
  return (
        <div className="card">
            <div className="card-body text-center p-5">
                <svg width="86" height="73" viewBox="0 0 86 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="var(--primary-color)" d="M21.59 18.6738L12.985 61.8549C12.4006 64.7875 14.344 67.6982 17.2767 68.2826L18.5913 68.5446L60.4578 76.8876C63.3904 77.472 66.3011 75.5286 66.8855 72.596L77.3646 20.0101L77.6468 18.5943C78.2312 15.6617 76.2878 12.751 73.3551 12.1666L40.9946 5.71787"/>
                    <path d="M21.59 18.6738L12.985 61.8549C12.4006 64.7875 14.344 67.6982 17.2767 68.2826L18.5913 68.5446L60.4578 76.8876C63.3904 77.472 66.3011 75.5286 66.8855 72.596L77.3646 20.0101L77.6468 18.5943C78.2312 15.6617 76.2878 12.751 73.3551 12.1666L40.9946 5.71787" stroke="#4D6173" strokeWidth="1.61896" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M40.9951 5.71802L37.7708 21.8983L21.5905 18.6739L40.9951 5.71802Z" fill="#4D6173" stroke="#4D6173" strokeWidth="1.61896" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fill="var(--accent-color)" d="M58.0265 16.0765L68.6699 58.8008C69.3927 61.7025 67.5896 64.702 64.6879 65.4249L63.3872 65.7489L21.9635 76.0682C19.0619 76.7911 16.0623 74.9879 15.3395 72.0863L2.37804 20.0566L2.02908 18.6558C1.30623 15.7542 3.10938 12.7546 6.01103 12.0317L38.0293 4.05546"/>
                    <path d="M58.0265 16.0765L68.6699 58.8008C69.3927 61.7025 67.5896 64.702 64.6879 65.4249L63.3872 65.7489L21.9635 76.0682C19.0619 76.7911 16.0623 74.9879 15.3395 72.0863L2.37804 20.0566L2.02908 18.6558C1.30623 15.7542 3.10938 12.7546 6.01103 12.0317L38.0293 4.05546" stroke="#4D6173" strokeWidth="1.61896" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M38.0293 4.05542L42.0174 20.0645L58.0266 16.0764L38.0293 4.05542Z" fill="#4D6173" stroke="#4D6173" strokeWidth="1.61896" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M68.4955 22.2298V66.26C68.4955 69.2503 66.0207 71.7251 63.0304 71.7251H61.6899H19.0003C16.0099 71.7251 13.5352 69.2503 13.5352 66.26V12.6402V11.1965C13.5352 8.20621 16.0099 5.73145 19.0003 5.73145H51.9971" fill="white"/>
                    <path d="M68.4955 22.2298V66.26C68.4955 69.2503 66.0207 71.7251 63.0304 71.7251H61.6899H19.0003C16.0099 71.7251 13.5352 69.2503 13.5352 66.26V12.6402V11.1965C13.5352 8.20621 16.0099 5.73145 19.0003 5.73145H51.9971" stroke="#4D6173" strokeWidth="1.61896" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M51.9971 5.73145V22.2298H68.4955L51.9971 5.73145Z" fill="#4D6173" stroke="#4D6173" strokeWidth="1.61896" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fill="var(--primary-color)" d="M74.8404 65.1407L60.1856 56.0998L62.2604 52.7397L76.908 61.7922L74.8404 65.1407Z" stroke="#4D6173" strokeWidth="0.930904" strokeMiterlimit="10"/>
                    <path d="M82.15 69.695L70.9111 62.7552C69.9726 62.1757 69.68 60.9388 70.2595 60.0003C70.8391 59.0618 72.076 58.7693 73.0145 59.3488L84.2534 66.2886C85.1919 66.8681 85.4844 68.105 84.9049 69.0435C84.3254 69.982 83.0885 70.2745 82.15 69.695Z" fill="#4D6173" stroke="#4D6173" strokeWidth="0.930904" strokeMiterlimit="10"/>
                    <path fill="var(--primary-color)" d="M81.6411 70.4286L67.1811 61.4999C66.8451 61.2924 66.741 60.852 66.9462 60.5066L69.2285 56.8105C69.436 56.4745 69.8764 56.3703 70.2218 56.5756L84.6818 65.5043C85.0178 65.7118 85.1219 66.1522 84.9167 66.4976L82.6344 70.1937C82.4176 70.5319 81.9771 70.6361 81.6411 70.4286Z" stroke="#4D6173" strokeWidth="0.930904" strokeMiterlimit="10"/>
                    <path fill="var(--primary-color)" d="M37.7403 21.1073C25.3338 24.0414 17.6571 36.4737 20.5912 48.8802C23.523 61.2774 35.9576 68.9634 48.3547 66.0316C60.7612 63.0975 68.4379 50.6652 65.5039 38.2587C62.5698 25.8522 50.1375 18.1755 37.7403 21.1073ZM47.3802 61.8689C37.4194 64.2245 27.4123 57.9334 25.0212 47.8227C22.6301 37.7119 28.7612 27.6141 38.722 25.2584C48.6829 22.9028 58.6899 29.1939 61.081 39.3046C63.4721 49.4154 57.3316 59.5154 47.3802 61.8689Z" stroke="#4D6173" strokeWidth="0.930904" strokeMiterlimit="10"/>
                    <ellipse cx="43.0139" cy="43.4264" rx="18.0856" ry="18.2322" transform="rotate(-13.3056 43.0139 43.4264)" fill="white"/>
                    <path fill="var(--primary-color)" d="M50.2239 44.0262C51.5954 44.0212 52.7667 42.8413 52.7616 41.4698C52.7566 40.0983 51.5768 38.9271 50.2053 38.9321C48.8338 38.9371 47.6625 40.117 47.6675 41.4885C47.6729 42.9579 48.7544 44.0316 50.2239 44.0262Z"/>
                    <path fill="var(--primary-color)" d="M48.7353 50.8377L47.4618 50.8423C46.3756 48.4951 43.5314 47.6238 41.2825 48.8076C40.4023 49.2027 39.6214 49.9893 39.2328 50.8724L37.9593 50.8771C39.0258 47.8362 42.5468 46.2559 45.49 47.4207C46.8636 48.0035 48.1415 49.1744 48.7353 50.8377Z"/>
                    <path fill="var(--primary-color)" d="M35.6536 44.0978C37.0251 44.0927 38.1963 42.9129 38.1913 41.5414C38.1863 40.1699 37.0065 38.9986 35.635 39.0036C34.2635 39.0086 33.0922 40.1885 33.0972 41.56C33.2005 43.0291 34.2821 44.1028 35.6536 44.0978Z"/>
                </svg>
                    
                <div className="mt-4 mb-3">
                    <span className="text-muted">No data to show</span>
                </div>
                <button type="button" className="btn btn-white border me-1">Get Started</button>
                <Link to='/' type="button" className="btn btn-primary border">Back to Home</Link>
            </div>
        </div>
    )
}

export default NoData