export const singleTitleMapping = {
    "/": "Dashboard",
    "/index": "Dashboard",
    "/my-profile": "Profile",
    "/employee-profile": "Employee Profile",
    "/hotels": "Hotels",
    "/transaction": "Transaction",
    "/room-booking-list": "Booking List",
    "/checkin-out": "Check Out",
    "/room-status": "Room Status",
    "/room-facilites-list": "Facilites List",
    "/facilites-details": "Facilites Details List",
    "/room-size": "Room Size List",
    "/housekeeping-assign-room": "Assign House Keeping",
    "/housekeeping-roomcleaning": "Room Cleaning List",
    "/housekeeping-checklist": "Housekeeping Checklist",
    "/housekeeping-qrlist": "Room QR-List",
    "/housekeeping-report": "Cleaning Report",      
    "/cab-list": "Cab List",
    "/cab-booking": "Cab Booking",
    "/items-unit-list": "Unit Items List",
    "/items-list": "Items List",
    "/items-destroyed-list": "Destroyed List",
    "/items-category-list": "Category List",
    "/items-suppliers-list": "Suppliers List",
    "/personalised": "Personalised",
    "/personalised-jacuzzi": "Jacuzzi Booking",
    "/personalised-swimmingpool": "Swimming Pool Booking",
    "/personalised-spa": "Spa Booking",
    "/personalised-pedicure": "Body Massage and Pedicure Booking",
    "/personalised-theater": "Theater Booking",
    "/personalised-banquet": "Banquet Booking",
    "/personalised-settings": "Personalised Settings",
    "/booking-report": "Booking Reports",
    "/purchase-report": "Purchase Report",
    "/stock-report": "Stock Report",
    "/bed-list": "Bed List",
    "/booking-type-list": "Booking Type List",
    "/commission-list": "Commission List",
    "/complementary-list": "Complementary List",
    "/floor-plan-list": "Floor Plan List",
    "/room-list": "Room List",
    "/room-image": "Room Images",
    "/promocode-list": "Promocode List",
    "/room-booking": "Room Booking",
    "/index-hr": "Dashboard",
    "/hrms": "HRMS",
    "/duty-assigned-list": "Assigned Employee List",
    "/duty-shift-list": "Duty Shift List",
    "/duty-roster-list": "Duty Roster List",
    "/employees-all": "Employee",
    "/employees-leave": "Leave Request",
    "/employees-attendance": "Attendance",
    "/employees-departments": "Departments",
    "/employees-loan": "Employee Loan",
    "/employees-appraisal": "Employee Appraisal",
    "/employees-add": "Add Employees",
    "/payroll-employee-salary": "Employee Salary",
    "/payroll-payslip": "Payslip",
    "/payroll-report": "Report",
    "/tuning": "Tuning",
    "/common-settings": "Common Settings",
    "/payment-set": "Payment Settings",
    "/accounts-setting": "Accounts Settings",
    "/role-add": "Add Role",
    "/role-list": "Role List",
    "/role-permission": "Role Permission",
    "/role-create-list": "Role Create List",
    "/role-assign": "Role Assign",
    "/role-access-list": "Role Access List",
    "/about-us": "About Us",
    "/contact-us" : "Contact Us",
    "/privacy-policy": "Privacy Policy",
    "/terms-conditions": "Terms Conditions",
    "/calendar": "Calendar",
    "/inbox": "Inbox",
    "/chat": "Chat",
    "/blog": "Blog",
    "/blog-detail": "BlogDetail",
    "/filemanager": "File Manager",
    "/search": "Search Page",
    "/changelog": "Changelog",
    "/app": "Apps",
  };