import qrImg from '../../../../../../assets/images/qr.png';

export const qrListData = [
    {
        'roomNo':'Room No.101',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.102',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.103',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.104',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.105',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.106',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.107',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.108',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.109',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.110',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.111',
        'qrImg': qrImg
    },
    {
        'roomNo':'Room No.112',
        'qrImg': qrImg
    }
]