export const houseKeepingData = [
    {
        roomNo:'Room No. 101',
        assign:'Booked'
    },
    {
        roomNo:'Room No. 102',
        assign:'Booked'
    },
    {
        roomNo:'Room No. 103',
        assign:'Ready'
    },
    {
        roomNo:'Room No. 104',
        assign:'Ready'
    },
    {
        roomNo:'Room No. 105',
        assign:'Booked'
    },
    {
        roomNo:'Room No. 106',
        assign:'Booked'
    },
    {
        roomNo:'Room No. 107',
        assign:'Ready'
    },
    {
        roomNo:'Room No. 108',
        assign:'Ready'
    },
    {
        roomNo:'Room No. B-1201',
        assign:'Ready'
    },
    {
        roomNo:'Room No. B-1202',
        assign:'Ready'
    },
    {
        roomNo:'Room No. B-1203',
        assign:'Ready'
    },
    {
        roomNo:'Room No. D-1201',
        assign:'Ready'
    },
    {
        roomNo:'Room No. D-1201',
        assign:'Ready'
    },
    {
        roomNo:'Room No. D-1201',
        assign:'Ready'
    },
    {
        roomNo:'Room No. E-1201',
        assign:'Ready'
    },
    {
        roomNo:'Room No. E-1201',
        assign:'Ready'
    },
    {
        roomNo:'Room No. E-1201',
        assign:'Ready'
    },
    {
        roomNo:'Room No. G-1201',
        assign:'Ready'
    },

]