export const personalisedData = [
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 12h16a1 1 0 0 1 1 1v3a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4v-3a1 1 0 0 1 1 -1z" />
            <path d="M6 12v-7a2 2 0 0 1 2 -2h3v2.25" />
            <path d="M4 21l1 -1.5" />
            <path d="M20 21l-1 -1.5" />
        </svg>,
        title:'Jacuzzi',
        text:'Description text here...',
        path:'personalised-jacuzzi',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M16 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M6 11l4 -2l3.5 3l-1.5 2" />
            <path d="M3 16.75a2.4 2.4 0 0 0 1 .25a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 1 -.25" />
        </svg>,
        title:'Swimming',
        text:'Description text here...',
        path:'personalised-swimmingpool',
        theme_color:'var(--theme-color2)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3.486 8.965c.168 .02 .34 .033 .514 .035c.79 .009 1.539 -.178 2 -.5c.461 -.32 1.21 -.507 2 -.5c.79 -.007 1.539 .18 2 .5c.461 .322 1.21 .509 2 .5c.79 .009 1.539 -.178 2 -.5c.461 -.32 1.21 -.507 2 -.5c.79 -.007 1.539 .18 2 .5c.461 .322 1.21 .509 2 .5c.17 0 .339 -.014 .503 -.034" />
            <path d="M3 6l1.721 10.329a2 2 0 0 0 1.973 1.671h10.612a2 2 0 0 0 1.973 -1.671l1.721 -10.329" />
            <path d="M10 15h.01" />
            <path d="M14 15h.01" />
            <path d="M14 12h.01" />
            <path d="M10 12h.01" />
        </svg>,
        title:'Spa',
        text:'Description text here...',
        path:'personalised-spa',
        theme_color:'var(--theme-color3)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24"  viewBox="0 0 24 24"  stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M13 3v6l4.798 5.142a4 4 0 0 1 -5.441 5.86l-6.736 -6.41a2 2 0 0 1 -.621 -1.451v-9.141h8z" />
            <path d="M7.895 15.768c.708 -.721 1.105 -1.677 1.105 -2.768a4 4 0 0 0 -4 -4" />
        </svg>,
        title:'Pedicure',
        text:'Description text here...',
        path:'personalised-pedicure',
        theme_color:'var(--theme-color5)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 20h16" />
            <path d="M20 16v-10a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v10l4 -6c2.667 1.333 5.333 1.333 8 0l4 6z" />
        </svg>,
        title:'Theater',
        text:'Description text here...',
        path:'personalised-theater',
        theme_color:'var(--theme-color6)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 21h7v-3a2 2 0 0 1 4 0v3h7" />
            <path d="M6 21l0 -9" />
            <path d="M18 21l0 -9" />
            <path d="M6 12h12a3 3 0 0 0 3 -3a9 8 0 0 1 -9 -6a9 8 0 0 1 -9 6a3 3 0 0 0 3 3" />
        </svg>,
        title:'Banquet',
        text:'Description text here...',
        path:'personalised-banquet',
        theme_color:'var(--theme-color4)'
    }, 
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24"  viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M12.003 21c-.732 .001 -1.465 -.438 -1.678 -1.317a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c.886 .215 1.325 .957 1.318 1.694" />
            <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
            <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M19.001 15.5v1.5" />
            <path d="M19.001 21v1.5" />
            <path d="M22.032 17.25l-1.299 .75" />
            <path d="M17.27 20l-1.3 .75" />
            <path d="M15.97 17.25l1.3 .75" />
            <path d="M20.733 20l1.3 .75" />
        </svg>,
        title:'Personalised Settings',
        text:'Description text here...',
        path:'personalised-settings',
        theme_color:'var(--theme-color1)'
    }
]