import React from 'react'

import './iconStyle.css';
import af from '../../../../../assets/images/flags/4x3/af.svg';
import ax from "../../../../../assets/images/flags/4x3/ax.svg";
import al from "../../../../../assets/images/flags/4x3/al.svg";
import dz from "../../../../../assets/images/flags/4x3/dz.svg";
import as from "../../../../../assets/images/flags/4x3/as.svg";
import ad from "../../../../../assets/images/flags/4x3/ad.svg";
import ao from "../../../../../assets/images/flags/4x3/ao.svg";
import ai from "../../../../../assets/images/flags/4x3/ai.svg";
import ag from "../../../../../assets/images/flags/4x3/ag.svg";
import ar from "../../../../../assets/images/flags/4x3/ar.svg";
import am from "../../../../../assets/images/flags/4x3/am.svg";
import aw from "../../../../../assets/images/flags/4x3/aw.svg";
import au from "../../../../../assets/images/flags/4x3/au.svg";
import at from "../../../../../assets/images/flags/4x3/at.svg";
import az from "../../../../../assets/images/flags/4x3/az.svg";
import bs from "../../../../../assets/images/flags/4x3/bs.svg";
import bh from "../../../../../assets/images/flags/4x3/bh.svg";
import bd from "../../../../../assets/images/flags/4x3/bd.svg";
import bb from "../../../../../assets/images/flags/4x3/bb.svg";
import by from "../../../../../assets/images/flags/4x3/by.svg";
import be from "../../../../../assets/images/flags/4x3/be.svg";
import bz from "../../../../../assets/images/flags/4x3/bz.svg";
import bj from "../../../../../assets/images/flags/4x3/bj.svg";
import bm from "../../../../../assets/images/flags/4x3/bm.svg";
import bt from "../../../../../assets/images/flags/4x3/bt.svg";
import bo from "../../../../../assets/images/flags/4x3/bo.svg";
import bq from "../../../../../assets/images/flags/4x3/bq.svg";
import ba from "../../../../../assets/images/flags/4x3/ba.svg";
import bw from "../../../../../assets/images/flags/4x3/bw.svg";
import br from "../../../../../assets/images/flags/4x3/br.svg";
import io from "../../../../../assets/images/flags/4x3/io.svg";
import bn from "../../../../../assets/images/flags/4x3/bn.svg";
import bg from "../../../../../assets/images/flags/4x3/bg.svg";
import bf from "../../../../../assets/images/flags/4x3/bf.svg";
import bi from "../../../../../assets/images/flags/4x3/bi.svg";
import cv from "../../../../../assets/images/flags/4x3/cv.svg";
import kh from "../../../../../assets/images/flags/4x3/kh.svg";
import cm from "../../../../../assets/images/flags/4x3/cm.svg";
import ca from "../../../../../assets/images/flags/4x3/ca.svg";
import ky from "../../../../../assets/images/flags/4x3/ky.svg";
import cf from "../../../../../assets/images/flags/4x3/cf.svg";
import td from "../../../../../assets/images/flags/4x3/td.svg";
import cl from "../../../../../assets/images/flags/4x3/cl.svg";
import cn from "../../../../../assets/images/flags/4x3/cn.svg";
import cx from "../../../../../assets/images/flags/4x3/cx.svg";
import cc from "../../../../../assets/images/flags/4x3/cc.svg";
import co from "../../../../../assets/images/flags/4x3/co.svg";
import km from "../../../../../assets/images/flags/4x3/km.svg";
import ck from "../../../../../assets/images/flags/4x3/ck.svg";
import cr from "../../../../../assets/images/flags/4x3/cr.svg";
import hr from "../../../../../assets/images/flags/4x3/hr.svg";
import cu from "../../../../../assets/images/flags/4x3/cu.svg";
import cw from "../../../../../assets/images/flags/4x3/cw.svg";
import cy from "../../../../../assets/images/flags/4x3/cy.svg";
import cz from "../../../../../assets/images/flags/4x3/cz.svg";
import ci from "../../../../../assets/images/flags/4x3/ci.svg";
import cd from "../../../../../assets/images/flags/4x3/cd.svg";
import dk from "../../../../../assets/images/flags/4x3/dk.svg";
import dj from "../../../../../assets/images/flags/4x3/dj.svg";
import dm from "../../../../../assets/images/flags/4x3/dm.svg";
import _do from "../../../../../assets/images/flags/4x3/do.svg";
import ec from "../../../../../assets/images/flags/4x3/ec.svg";
import eg from "../../../../../assets/images/flags/4x3/eg.svg";
import sv from "../../../../../assets/images/flags/4x3/sv.svg";
import gbEng from "../../../../../assets/images/flags/4x3/gb-eng.svg";
import gq from "../../../../../assets/images/flags/4x3/gq.svg";
import er from "../../../../../assets/images/flags/4x3/er.svg";
import ee from "../../../../../assets/images/flags/4x3/ee.svg";
import et from "../../../../../assets/images/flags/4x3/et.svg";
import fk from "../../../../../assets/images/flags/4x3/fk.svg";
import fo from "../../../../../assets/images/flags/4x3/fo.svg";
import fm from "../../../../../assets/images/flags/4x3/fm.svg";
import fj from "../../../../../assets/images/flags/4x3/fj.svg";
import fi from "../../../../../assets/images/flags/4x3/fi.svg";
import fr from "../../../../../assets/images/flags/4x3/fr.svg";
import gf from "../../../../../assets/images/flags/4x3/gf.svg";
import pf from "../../../../../assets/images/flags/4x3/pf.svg";
import tf from "../../../../../assets/images/flags/4x3/tf.svg";
import ga from "../../../../../assets/images/flags/4x3/ga.svg";
import gm from "../../../../../assets/images/flags/4x3/gm.svg";
import ge from "../../../../../assets/images/flags/4x3/ge.svg";
import de from "../../../../../assets/images/flags/4x3/de.svg";
import gh from "../../../../../assets/images/flags/4x3/gh.svg";
import gi from "../../../../../assets/images/flags/4x3/gi.svg";
import gr from "../../../../../assets/images/flags/4x3/gr.svg";
import gl from "../../../../../assets/images/flags/4x3/gl.svg";
import gd from "../../../../../assets/images/flags/4x3/gd.svg";
import gp from "../../../../../assets/images/flags/4x3/gp.svg";
import gu from "../../../../../assets/images/flags/4x3/gu.svg";
import gt from "../../../../../assets/images/flags/4x3/gt.svg";
import gg from "../../../../../assets/images/flags/4x3/gg.svg";
import gn from "../../../../../assets/images/flags/4x3/gn.svg";
import gw from "../../../../../assets/images/flags/4x3/gw.svg";
import gy from "../../../../../assets/images/flags/4x3/gy.svg";
import ht from "../../../../../assets/images/flags/4x3/ht.svg";
import va from "../../../../../assets/images/flags/4x3/va.svg";
import hn from "../../../../../assets/images/flags/4x3/hn.svg";
import hk from "../../../../../assets/images/flags/4x3/hk.svg";
import hu from "../../../../../assets/images/flags/4x3/hu.svg";
import is from "../../../../../assets/images/flags/4x3/is.svg";
import _in from "../../../../../assets/images/flags/4x3/in.svg";
import id from "../../../../../assets/images/flags/4x3/id.svg";
import ir from "../../../../../assets/images/flags/4x3/ir.svg";
import iq from "../../../../../assets/images/flags/4x3/iq.svg";
import ie from "../../../../../assets/images/flags/4x3/ie.svg";
import im from "../../../../../assets/images/flags/4x3/im.svg";
import il from "../../../../../assets/images/flags/4x3/il.svg";
import it from "../../../../../assets/images/flags/4x3/it.svg";
import jm from "../../../../../assets/images/flags/4x3/jm.svg";
import jp from "../../../../../assets/images/flags/4x3/jp.svg";
import je from "../../../../../assets/images/flags/4x3/je.svg";
import jo from "../../../../../assets/images/flags/4x3/jo.svg";
import kz from "../../../../../assets/images/flags/4x3/kz.svg";
import ke from "../../../../../assets/images/flags/4x3/ke.svg";
import ki from "../../../../../assets/images/flags/4x3/ki.svg";
import xk from "../../../../../assets/images/flags/4x3/xk.svg";
import kw from "../../../../../assets/images/flags/4x3/kw.svg";
import kg from "../../../../../assets/images/flags/4x3/kg.svg";
import la from "../../../../../assets/images/flags/4x3/la.svg";
import lv from "../../../../../assets/images/flags/4x3/lv.svg";
import lb from "../../../../../assets/images/flags/4x3/lb.svg";
import ls from "../../../../../assets/images/flags/4x3/ls.svg";
import lr from "../../../../../assets/images/flags/4x3/lr.svg";
import ly from "../../../../../assets/images/flags/4x3/ly.svg";
import li from "../../../../../assets/images/flags/4x3/li.svg";
import lt from "../../../../../assets/images/flags/4x3/lt.svg";
import lu from "../../../../../assets/images/flags/4x3/lu.svg";
import mo from "../../../../../assets/images/flags/4x3/mo.svg";
import mg from "../../../../../assets/images/flags/4x3/mg.svg";
import mw from "../../../../../assets/images/flags/4x3/mw.svg";
import my from "../../../../../assets/images/flags/4x3/my.svg";
import mv from "../../../../../assets/images/flags/4x3/mv.svg";
import ml from "../../../../../assets/images/flags/4x3/ml.svg";
import mt from "../../../../../assets/images/flags/4x3/mt.svg";
import mh from "../../../../../assets/images/flags/4x3/mh.svg";
import mq from "../../../../../assets/images/flags/4x3/mq.svg";
import mr from "../../../../../assets/images/flags/4x3/mr.svg";
import mu from "../../../../../assets/images/flags/4x3/mu.svg";
import yt from "../../../../../assets/images/flags/4x3/yt.svg";
import mx from "../../../../../assets/images/flags/4x3/mx.svg";
import md from "../../../../../assets/images/flags/4x3/md.svg";
import mc from "../../../../../assets/images/flags/4x3/mc.svg";
import mn from "../../../../../assets/images/flags/4x3/mn.svg";
import me from "../../../../../assets/images/flags/4x3/me.svg";
import ms from "../../../../../assets/images/flags/4x3/ms.svg";
import ma from "../../../../../assets/images/flags/4x3/ma.svg";
import mz from "../../../../../assets/images/flags/4x3/mz.svg";
import mm from "../../../../../assets/images/flags/4x3/mm.svg";
import na from "../../../../../assets/images/flags/4x3/na.svg";
import nr from "../../../../../assets/images/flags/4x3/nr.svg";
import np from "../../../../../assets/images/flags/4x3/np.svg";
import nl from "../../../../../assets/images/flags/4x3/nl.svg";
import nc from "../../../../../assets/images/flags/4x3/nc.svg";
import nz from "../../../../../assets/images/flags/4x3/nz.svg";
import ni from "../../../../../assets/images/flags/4x3/ni.svg";
import ne from "../../../../../assets/images/flags/4x3/ne.svg";
import ng from "../../../../../assets/images/flags/4x3/ng.svg";
import nu from "../../../../../assets/images/flags/4x3/nu.svg";
import nf from "../../../../../assets/images/flags/4x3/nf.svg";
import kp from "../../../../../assets/images/flags/4x3/kp.svg";
import mk from "../../../../../assets/images/flags/4x3/mk.svg";
import gbNIR from "../../../../../assets/images/flags/4x3/gb-nir.svg";
import mp from "../../../../../assets/images/flags/4x3/mp.svg";
import no from "../../../../../assets/images/flags/4x3/no.svg";
import om from "../../../../../assets/images/flags/4x3/om.svg";
import pk from "../../../../../assets/images/flags/4x3/pk.svg";
import pw from "../../../../../assets/images/flags/4x3/pw.svg";
import pa from "../../../../../assets/images/flags/4x3/pa.svg";
import pg from "../../../../../assets/images/flags/4x3/pg.svg";
import py from "../../../../../assets/images/flags/4x3/py.svg";
import pe from "../../../../../assets/images/flags/4x3/pe.svg";
import ph from "../../../../../assets/images/flags/4x3/ph.svg";
import pn from "../../../../../assets/images/flags/4x3/pn.svg";
import pl from "../../../../../assets/images/flags/4x3/pl.svg";
import pt from "../../../../../assets/images/flags/4x3/pt.svg";
import pr from "../../../../../assets/images/flags/4x3/pr.svg";
import qa from "../../../../../assets/images/flags/4x3/qa.svg";
import cg from "../../../../../assets/images/flags/4x3/cg.svg";
import ro from "../../../../../assets/images/flags/4x3/ro.svg";
import ru from "../../../../../assets/images/flags/4x3/ru.svg";
import rw from "../../../../../assets/images/flags/4x3/rw.svg";
import re from "../../../../../assets/images/flags/4x3/re.svg";
import bl from "../../../../../assets/images/flags/4x3/bl.svg";
import sh from "../../../../../assets/images/flags/4x3/sh.svg";
import kn from "../../../../../assets/images/flags/4x3/kn.svg";
import lc from "../../../../../assets/images/flags/4x3/lc.svg";
import mf from "../../../../../assets/images/flags/4x3/mf.svg";
import pm from "../../../../../assets/images/flags/4x3/pm.svg";
import vc from "../../../../../assets/images/flags/4x3/vc.svg";
import ws from "../../../../../assets/images/flags/4x3/ws.svg";
import sm from "../../../../../assets/images/flags/4x3/sm.svg";
import st from "../../../../../assets/images/flags/4x3/st.svg";
import sa from "../../../../../assets/images/flags/4x3/sa.svg";
import gbSCT from "../../../../../assets/images/flags/4x3/gb-sct.svg";
import sn from "../../../../../assets/images/flags/4x3/sn.svg";
import rs from "../../../../../assets/images/flags/4x3/rs.svg";
import sc from "../../../../../assets/images/flags/4x3/sc.svg";
import sl from "../../../../../assets/images/flags/4x3/sl.svg";
import sg from "../../../../../assets/images/flags/4x3/sg.svg";
import sx from "../../../../../assets/images/flags/4x3/sx.svg";
import sk from "../../../../../assets/images/flags/4x3/sk.svg";
import si from "../../../../../assets/images/flags/4x3/si.svg";
import sb from "../../../../../assets/images/flags/4x3/sb.svg";
import so from "../../../../../assets/images/flags/4x3/so.svg";
import za from "../../../../../assets/images/flags/4x3/za.svg";
import gs from "../../../../../assets/images/flags/4x3/gs.svg";
import kr from "../../../../../assets/images/flags/4x3/kr.svg";
import ss from "../../../../../assets/images/flags/4x3/ss.svg";
import es from "../../../../../assets/images/flags/4x3/es.svg";
import lk from "../../../../../assets/images/flags/4x3/lk.svg";
import ps from "../../../../../assets/images/flags/4x3/ps.svg";
import sd from "../../../../../assets/images/flags/4x3/sd.svg";
import sr from "../../../../../assets/images/flags/4x3/sr.svg";
import sj from "../../../../../assets/images/flags/4x3/sj.svg";
import sz from "../../../../../assets/images/flags/4x3/sz.svg";
import se from "../../../../../assets/images/flags/4x3/se.svg";
import ch from "../../../../../assets/images/flags/4x3/ch.svg";
import sy from "../../../../../assets/images/flags/4x3/sy.svg";
import tw from "../../../../../assets/images/flags/4x3/tw.svg";
import tj from "../../../../../assets/images/flags/4x3/tj.svg";
import tz from "../../../../../assets/images/flags/4x3/tz.svg";
import th from "../../../../../assets/images/flags/4x3/th.svg";
import tl from "../../../../../assets/images/flags/4x3/tl.svg";
import tg from '../../../../../assets/images/flags/4x3/tg.svg';
import tk from '../../../../../assets/images/flags/4x3/tk.svg';
import to from '../../../../../assets/images/flags/4x3/to.svg';
import tt from '../../../../../assets/images/flags/4x3/tt.svg';
import tn from '../../../../../assets/images/flags/4x3/tn.svg';
import tr from '../../../../../assets/images/flags/4x3/tr.svg';
import tm from '../../../../../assets/images/flags/4x3/tm.svg';
import tc from '../../../../../assets/images/flags/4x3/tc.svg';
import tv from '../../../../../assets/images/flags/4x3/tv.svg';
import ug from '../../../../../assets/images/flags/4x3/ug.svg';
import ua from '../../../../../assets/images/flags/4x3/ua.svg';
import ae from '../../../../../assets/images/flags/4x3/ae.svg';
import gb from '../../../../../assets/images/flags/4x3/gb.svg';
import um from '../../../../../assets/images/flags/4x3/um.svg';
import us from '../../../../../assets/images/flags/4x3/us.svg';
import uy from '../../../../../assets/images/flags/4x3/uy.svg';
import uz from '../../../../../assets/images/flags/4x3/uz.svg';
import vu from '../../../../../assets/images/flags/4x3/vu.svg';
import ve from '../../../../../assets/images/flags/4x3/ve.svg';
import vn from '../../../../../assets/images/flags/4x3/vn.svg';
import vg from '../../../../../assets/images/flags/4x3/vg.svg';
import vi from '../../../../../assets/images/flags/4x3/vi.svg';
import gbWls from '../../../../../assets/images/flags/4x3/gb-wls.svg';
import wf from '../../../../../assets/images/flags/4x3/wf.svg';
import eh from '../../../../../assets/images/flags/4x3/eh.svg';
import ye from '../../../../../assets/images/flags/4x3/ye.svg';
import zm from '../../../../../assets/images/flags/4x3/zm.svg';
import zw from '../../../../../assets/images/flags/4x3/zw.svg';

const CountryFlagsIcon = () => {

	const flagsCode = `<span className="flag-icon flag-icon-gr"></span>
<span className="flag-icon flag-icon-gr flag-icon-squared"></span> `;

  return (
    	<div className="content px-xl-5 px-lg-4 px-3 py-3 page-body bg-card">
			<h4 className="mb-4">Country Flags icon</h4>
			<p className="lead">A collection of all country flags in SVG — plus the CSS for easier integration. See the <a href="https://flagicons.lipis.dev/" rel="nofollow"  target="_blank">demo</a></p>
			<p className="lead">For using the flags inline with text add the classes .flag-icon and .flag-icon-xx (where xx is the ISO 3166-1-alpha-2 code of a country) to an empty <span>. If you want to have a squared version flag then add the class flag-icon-squared as well. Example:</span></p>
			<div className="card-body p-0">
				<div className="row g-xl-5 g-4">
					<div className="col-12">
						<div className="rounded-4" data-lang="html">
							<pre className='h6 language-html text-primary'><code>{flagsCode}</code></pre>
						</div>
						<div role="alert" className="alert alert-info">Check out a <a target="_blank" href="https://github.com/lipis/flag-icon-css">https://github.com/lipis/flag-icon-css</a> of the Flag Icon.</div>
					</div>
				</div>
				<div className="row g-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-2 mb-0 icons-list row-deck">
					<div className="col">
						<div className="country" data-name="Afghanistan" data-continent="Asia">
							<div className="name" title="Afghanistan">
								<span className="alpha-2">AF </span>
								Afghanistan
							</div>
							<div className="capital">Kabul</div>
							<img className="flag" src={af} alt="Afghanistan Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Aland Islands" data-continent="Europe">
							<div className="name" title="Aland Islands">
								<span className="alpha-2">AX </span>
								Aland Islands
							</div>
							<div className="capital">Mariehamn</div>
							<img className="flag" src={ax} alt="Aland Islands Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Albania" data-continent="Europe">
							<div className="name" title="Albania">
								<span className="alpha-2">AL </span>
								Albania
							</div>
							<div className="capital">Tirana</div>
							<img className="flag" src={al} alt="Albania Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Algeria" data-continent="Africa">
							<div className="name" title="Algeria">
								<span className="alpha-2">DZ </span>
								Algeria
							</div>
							<div className="capital">Algiers</div>
							<img className="flag" src={dz} alt="Algeria Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="American Samoa" data-continent="Oceania">
							<div className="name" title="American Samoa">
								<span className="alpha-2">AS </span>
								American Samoa
							</div>
							<div className="capital">Pago Pago</div>
							<img className="flag" src={as} alt="American Samoa Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Andorra" data-continent="Europe">
							<div className="name" title="Andorra">
								<span className="alpha-2">AD </span>
								Andorra
							</div>
							<div className="capital">Andorra la Vella</div>
							<img className="flag" src={ad} alt="Andorra Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Angola" data-continent="Africa">
							<div className="name" title="Angola">
								<span className="alpha-2">AO </span>
								Angola
							</div>
							<div className="capital">Luanda</div>
							<img className="flag" src={ao} alt="Angola Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Anguilla" data-continent="North America">
							<div className="name" title="Anguilla">
								<span className="alpha-2">AI </span>
								Anguilla
							</div>
							<div className="capital">The Valley</div>
							<img className="flag" src={ai} alt="Anguilla Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Antigua and Barbuda" data-continent="North America">
							<div className="name" title="Antigua and Barbuda">
								<span className="alpha-2">AG </span>
								Antigua and Barbuda
							</div>
							<div className="capital">St. Jony's</div>
							<img className="flag" src={ag} alt="Antigua and Barbuda Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Argentina" data-continent="South America">
							<div className="name" title="Argentina">
								<span className="alpha-2">AR </span>
								Argentina
							</div>
							<div className="capital">Buenos Aires</div>
							<img className="flag" src={ar} alt="Argentina Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Armenia" data-continent="Asia">
							<div className="name" title="Armenia">
								<span className="alpha-2">AM </span>
								Armenia
							</div>
							<div className="capital">Yerevan</div>
							<img className="flag" src={am} alt="Armenia Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Aruba" data-continent="South America">
							<div className="name" title="Aruba">
								<span className="alpha-2">AW </span>
								Aruba
							</div>
							<div className="capital">Oranjestad</div>
							<img className="flag" src={aw} alt="Aruba Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Australia" data-continent="Oceania">
							<div className="name" title="Australia">
								<span className="alpha-2">AU </span>
								Australia
							</div>
							<div className="capital">Canberra</div>
							<img className="flag" src={au} alt="Australia Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Austria" data-continent="Europe">
							<div className="name" title="Austria">
								<span className="alpha-2">AT </span>
								Austria
							</div>
							<div className="capital">Vienna</div>
							<img className="flag" src={at} alt="Austria Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Azerbaijan" data-continent="Asia">
							<div className="name" title="Azerbaijan">
								<span className="alpha-2">AZ </span>
								Azerbaijan
							</div>
							<div className="capital">Baku</div>
							<img className="flag" src={az} alt="Azerbaijan Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Bahamas" data-continent="North America">
							<div className="name" title="Bahamas">
								<span className="alpha-2">BS </span>
								Bahamas
							</div>
							<div className="capital">Nassau</div>
							<img className="flag" src={bs} alt="Bahamas Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Bahrain" data-continent="Asia">
							<div className="name" title="Bahrain">
								<span className="alpha-2">BH </span>
								Bahrain
							</div>
							<div className="capital">Manama</div>
							<img className="flag" src={bh} alt="Bahrain Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Bangladesh" data-continent="Asia">
							<div className="name" title="Bangladesh">
								<span className="alpha-2">BD </span>
								Bangladesh
							</div>
							<div className="capital">Dhaka</div>
							<img className="flag" src={bd} alt="Bangladesh Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Barbados" data-continent="North America">
							<div className="name" title="Barbados">
								<span className="alpha-2">BB </span>
								Barbados
							</div>
							<div className="capital">Bridgetown</div>
							<img className="flag" src={bb} alt="Barbados Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Belarus" data-continent="Europe">
							<div className="name" title="Belarus">
								<span className="alpha-2">BY </span>
								Belarus
							</div>
							<div className="capital">Minsk</div>
							<img className="flag" src={by} alt="Belarus Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Belgium" data-continent="Europe">
							<div className="name" title="Belgium">
								<span className="alpha-2">BE </span>
								Belgium
							</div>
							<div className="capital">Brussels</div>
							<img className="flag" src={be} alt="Belgium Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Belize" data-continent="North America">
							<div className="name" title="Belize">
								<span className="alpha-2">BZ </span>
								Belize
							</div>
							<div className="capital">Belmopan</div>
							<img className="flag" src={bz} alt="Belize Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Benin" data-continent="Africa">
							<div className="name" title="Benin">
								<span className="alpha-2">BJ </span>
								Benin
							</div>
							<div className="capital">Porto-Novo</div>
							<img className="flag" src={bj} alt="Benin Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Bermuda" data-continent="North America">
							<div className="name" title="Bermuda">
								<span className="alpha-2">BM </span>
								Bermuda
							</div>
							<div className="capital">Hamilton</div>
							<img className="flag" src={bm} alt="Bermuda Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Bhutan" data-continent="Asia">
							<div className="name" title="Bhutan">
								<span className="alpha-2">BT </span>
								Bhutan
							</div>
							<div className="capital">Thimphu</div>
							<img className="flag" src={bt} alt="Bhutan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Bolivia (Plurinational State of)" data-continent="South America">
							<div className="name" title="Bolivia (Plurinational State of)">
								<span className="alpha-2">BO </span>
								Bolivia (Plurinational State of)
							</div>
							<div className="capital">Sucre</div>
							<img className="flag" src={bo} alt="Bolivia (Plurinational State of) Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Bonaire, Sint Eustatius and Saba" data-continent="South America">
							<div className="name" title="Bonaire, Sint Eustatius and Saba">
								<span className="alpha-2">BQ </span>
								Bonaire, Sint Eustatius and Saba
							</div>
							<div className="capital">Kralendijk</div>
							<img className="flag" src={bq} alt="Bonaire, Sint Eustatius and Saba Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Bosnia and Herzegovina" data-continent="Europe">
							<div className="name" title="Bosnia and Herzegovina">
								<span className="alpha-2">BA </span>
								Bosnia and Herzegovina
							</div>
							<div className="capital">Sarajevo</div>
							<img className="flag" src={ba} alt="Bosnia and Herzegovina Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Botswana" data-continent="Africa">
							<div className="name" title="Botswana">
								<span className="alpha-2">BW </span>
								Botswana
							</div>
							<div className="capital">Gaborone</div>
							<img className="flag" src={bw} alt="Botswana Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Brazil" data-continent="South America">
							<div className="name" title="Brazil">
								<span className="alpha-2">BR </span>
								Brazil
							</div>
							<div className="capital">Brasília</div>
							<img className="flag" src={br} alt="Brazil Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="British Indian Ocean Territory" data-continent="Asia">
							<div className="name" title="British Indian Ocean Territory">
								<span className="alpha-2">IO </span>
								British Indian Ocean Territory
							</div>
							<div className="capital">Diego Garcia</div>
							<img className="flag" src={io} alt="British Indian Ocean Territory Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Brunei Darussalam" data-continent="Asia">
							<div className="name" title="Brunei Darussalam">
								<span className="alpha-2">BN </span>
								Brunei Darussalam
							</div>
							<div className="capital">Bandar Seri Begawan</div>
							<img className="flag" src={bn} alt="Brunei Darussalam Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Bulgaria" data-continent="Europe">
							<div className="name" title="Bulgaria">
								<span className="alpha-2">BG </span>
								Bulgaria
							</div>
							<div className="capital">Sofia</div>
							<img className="flag" src={bg} alt="Bulgaria Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Burkina Faso" data-continent="Africa">
							<div className="name" title="Burkina Faso">
								<span className="alpha-2">BF </span>
								Burkina Faso
							</div>
							<div className="capital">Ouagadougou</div>
							<img className="flag" src={bf} alt="Burkina Faso Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Burundi" data-continent="Africa">
							<div className="name" title="Burundi">
								<span className="alpha-2">BI </span>
								Burundi
							</div>
							<div className="capital">Bujumbura</div>
							<img className="flag" src={bi} alt="Burundi Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cabo Verde" data-continent="Africa">
							<div className="name" title="Cabo Verde">
								<span className="alpha-2">CV </span>
								Cabo Verde
							</div>
							<div className="capital">Praia</div>
							<img className="flag" src={cv} alt="Cabo Verde Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cambodia" data-continent="Asia">
							<div className="name" title="Cambodia">
								<span className="alpha-2">KH </span>
								Cambodia
							</div>
							<div className="capital">Phnom Penh</div>
							<img className="flag" src={kh} alt="Cambodia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cameroon" data-continent="Africa">
							<div className="name" title="Cameroon">
								<span className="alpha-2">CM </span>
								Cameroon
							</div>
							<div className="capital">Yaoundé</div>
							<img className="flag" src={cm} alt="Cameroon Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Canada" data-continent="North America">
							<div className="name" title="Canada">
								<span className="alpha-2">CA </span>
								Canada
							</div>
							<div className="capital">Ottawa</div>
							<img className="flag" src={ca} alt="Canada Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cayman Islands" data-continent="North America">
							<div className="name" title="Cayman Islands">
								<span className="alpha-2">KY </span>
								Cayman Islands
							</div>
							<div className="capital">George Town</div>
							<img className="flag" src={ky} alt="Cayman Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Central African Republic" data-continent="Africa">
							<div className="name" title="Central African Republic">
								<span className="alpha-2">CF </span>
								Central African Republic
							</div>
							<div className="capital">Bangui</div>
							<img className="flag" src={cf} alt="Central African Republic Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Chad" data-continent="Africa">
							<div className="name" title="Chad">
								<span className="alpha-2">TD </span>
								Chad
							</div>
							<div className="capital">N'Djamena</div>
							<img className="flag" src={td} alt="Chad Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Chile" data-continent="South America">
							<div className="name" title="Chile">
								<span className="alpha-2">CL </span>
								Chile
							</div>
							<div className="capital">Santiago</div>
							<img className="flag" src={cl} alt="Chile Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="China" data-continent="Asia">
							<div className="name" title="China">
								<span className="alpha-2">CN </span>
								China
							</div>
							<div className="capital">Beijing</div>
							<img className="flag" src={cn} alt="China Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Christmas Island" data-continent="Asia">
							<div className="name" title="Christmas Island">
								<span className="alpha-2">CX </span>
								Christmas Island
							</div>
							<div className="capital">Flying Fish Cove</div>
							<img className="flag" src={cx} alt="Christmas Island Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cocos (Keeling) Islands" data-continent="Asia">
							<div className="name" title="Cocos (Keeling) Islands">
								<span className="alpha-2">CC </span>
								Cocos (Keeling) Islands
							</div>
							<div className="capital">West Island</div>
							<img className="flag" src={cc} alt="Cocos (Keeling) Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Colombia" data-continent="South America">
							<div className="name" title="Colombia">
								<span className="alpha-2">CO </span>
								Colombia
							</div>
							<div className="capital">Bogotá</div>
							<img className="flag" src={co} alt="Colombia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Comoros" data-continent="Africa">
							<div className="name" title="Comoros">
								<span className="alpha-2">KM </span>
								Comoros
							</div>
							<div className="capital">Moroni</div>
							<img className="flag" src={km} alt="Comoros Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cook Islands" data-continent="Oceania">
							<div className="name" title="Cook Islands">
								<span className="alpha-2">CK </span>
								Cook Islands
							</div>
							<div className="capital">Avarua</div>
							<img className="flag" src={ck} alt="Cook Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Costa Rica" data-continent="North America">
							<div className="name" title="Costa Rica">
								<span className="alpha-2">CR </span>
								Costa Rica
							</div>
							<div className="capital">San José</div>
							<img className="flag" src={cr} alt="Costa Rica Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Croatia" data-continent="Europe">
							<div className="name" title="Croatia">
								<span className="alpha-2">HR </span>
								Croatia
							</div>
							<div className="capital">Zagreb</div>
							<img className="flag" src={hr} alt="Croatia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cuba" data-continent="North America">
							<div className="name" title="Cuba">
								<span className="alpha-2">CU </span>
								Cuba
							</div>
							<div className="capital">Havana</div>
							<img className="flag" src={cu} alt="Cuba Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Curaçao" data-continent="South America">
							<div className="name" title="Curaçao">
								<span className="alpha-2">CW </span>
								Curaçao
							</div>
							<div className="capital">Willemstad</div>
							<img className="flag" src={cw} alt="Curaçao Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Cyprus" data-continent="Europe">
							<div className="name" title="Cyprus">
								<span className="alpha-2">CY </span>
								Cyprus
							</div>
							<div className="capital">Nicosia</div>
							<img className="flag" src={cy} alt="Cyprus Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Czech Republic" data-continent="Europe">
							<div className="name" title="Czech Republic">
								<span className="alpha-2">CZ </span>
								Czech Republic
							</div>
							<div className="capital">Prague</div>
							<img className="flag" src={cz} alt="Czech Republic Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Côte d'Ivoire" data-continent="Africa">
							<div className="name" title="Côte d'Ivoire">
								<span className="alpha-2">CI </span>
								Côte d'Ivoire
							</div>
							<div className="capital">Yamoussoukro</div>
							<img className="flag" src={ci} alt="Côte d'Ivoire Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Democratic Republic of the Congo" data-continent="Africa">
							<div className="name" title="Democratic Republic of the Congo">
								<span className="alpha-2">CD </span>
								Democratic Republic of the Congo
							</div>
							<div className="capital">Kinshasa</div>
							<img className="flag" src={cd} alt="Democratic Republic of the Congo Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Denmark" data-continent="Europe">
							<div className="name" title="Denmark">
								<span className="alpha-2">DK </span>
								Denmark
							</div>
							<div className="capital">Copenhagen</div>
							<img className="flag" src={dk} alt="Denmark Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Djibouti" data-continent="Africa">
							<div className="name" title="Djibouti">
								<span className="alpha-2">DJ </span>
								Djibouti
							</div>
							<div className="capital">Djibouti</div>
							<img className="flag" src={dj} alt="Djibouti Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Dominica" data-continent="North America">
							<div className="name" title="Dominica">
								<span className="alpha-2">DM </span>
								Dominica
							</div>
							<div className="capital">Roseau</div>
							<img className="flag" src={dm} alt="Dominica Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Dominican Republic" data-continent="North America">
							<div className="name" title="Dominican Republic">
								<span className="alpha-2">DO </span>
								Dominican Republic
							</div>
							<div className="capital">Santo Domingo</div>
							<img className="flag" src={_do} alt="Dominican Republic Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Ecuador" data-continent="South America">
							<div className="name" title="Ecuador">
								<span className="alpha-2">EC </span>
								Ecuador
							</div>
							<div className="capital">Quito</div>
							<img className="flag" src={ec} alt="Ecuador Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Egypt" data-continent="Africa">
							<div className="name" title="Egypt">
								<span className="alpha-2">EG </span>
								Egypt
							</div>
							<div className="capital">Cairo</div>
							<img className="flag" src={eg} alt="Egypt Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="El Salvador" data-continent="North America">
							<div className="name" title="El Salvador">
								<span className="alpha-2">SV </span>
								El Salvador
							</div>
							<div className="capital">San Salvador</div>
							<img className="flag" src={sv} alt="El Salvador Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="England" data-continent="Europe">
							<div className="name" title="England">
								<span className="alpha-2">GB -ENG</span>
								England
							</div>
							<div className="capital">London</div>
							<img className="flag" src={gbEng} alt="England Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Equatorial Guinea" data-continent="Africa">
							<div className="name" title="Equatorial Guinea">
								<span className="alpha-2">GQ </span>
								Equatorial Guinea
							</div>
							<div className="capital">Malabo</div>
							<img className="flag" src={gq} alt="Equatorial Guinea Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Eritrea" data-continent="Africa">
							<div className="name" title="Eritrea">
								<span className="alpha-2">ER </span>
								Eritrea
							</div>
							<div className="capital">Asmara</div>
							<img className="flag" src={er} alt="Eritrea Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Estonia" data-continent="Europe">
							<div className="name" title="Estonia">
								<span className="alpha-2">EE </span>
								Estonia
							</div>
							<div className="capital">Tallinn</div>
							<img className="flag" src={ee} alt="Estonia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Ethiopia" data-continent="Africa">
							<div className="name" title="Ethiopia">
								<span className="alpha-2">ET </span>
								Ethiopia
							</div>
							<div className="capital">Addis Ababa</div>
							<img className="flag" src={et} alt="Ethiopia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Falkland Islands" data-continent="South America">
							<div className="name" title="Falkland Islands">
								<span className="alpha-2">FK </span>
								Falkland Islands
							</div>
							<div className="capital">Stanley</div>
							<img className="flag" src={fk} alt="Falkland Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Faroe Islands" data-continent="Europe">
							<div className="name" title="Faroe Islands">
								<span className="alpha-2">FO </span>
								Faroe Islands
							</div>
							<div className="capital">Tórshavn</div>
							<img className="flag" src={fo} alt="Faroe Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Federated States of Micronesia" data-continent="Oceania">
							<div className="name" title="Federated States of Micronesia">
								<span className="alpha-2">FM </span>
								Federated States of Micronesia
							</div>
							<div className="capital">Palikir</div>
							<img className="flag" src={fm} alt="Federated States of Micronesia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Fiji" data-continent="Oceania">
							<div className="name" title="Fiji">
								<span className="alpha-2">FJ </span>
								Fiji
							</div>
							<div className="capital">Suva</div>
							<img className="flag" src={fj} alt="Fiji Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Finland" data-continent="Europe">
							<div className="name" title="Finland">
								<span className="alpha-2">FI </span>
								Finland
							</div>
							<div className="capital">Helsinki</div>
							<img className="flag" src={fi} alt="Finland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="France" data-continent="Europe">
							<div className="name" title="France">
								<span className="alpha-2">FR </span>
								France
							</div>
							<div className="capital">Paris</div>
							<img className="flag" src={fr} alt="France Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="French Guiana" data-continent="South America">
							<div className="name" title="French Guiana">
								<span className="alpha-2">GF </span>
								French Guiana
							</div>
							<div className="capital">Cayenne</div>
							<img className="flag" src={gf} alt="French Guiana Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="French Polynesia" data-continent="Oceania">
							<div className="name" title="French Polynesia">
								<span className="alpha-2">PF </span>
								French Polynesia
							</div>
							<div className="capital">Papeete</div>
							<img className="flag" src={pf} alt="French Polynesia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="French Southern Territories" data-continent="Africa">
							<div className="name" title="French Southern Territories">
								<span className="alpha-2">TF </span>
								French Southern Territories
							</div>
							<div className="capital">Saint-Pierre, Réunion</div>
							<img className="flag" src={tf} alt="French Southern Territories Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Gabon" data-continent="Africa">
							<div className="name" title="Gabon">
								<span className="alpha-2">GA </span>
								Gabon
							</div>
							<div className="capital">Libreville</div>
							<img className="flag" src={ga} alt="Gabon Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Gambia" data-continent="Africa">
							<div className="name" title="Gambia">
								<span className="alpha-2">GM </span>
								Gambia
							</div>
							<div className="capital">Banjul</div>
							<img className="flag" src={gm} alt="Gambia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Georgia" data-continent="Asia">
							<div className="name" title="Georgia">
								<span className="alpha-2">GE </span>
								Georgia
							</div>
							<div className="capital">Tbilisi</div>
							<img className="flag" src={ge} alt="Georgia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Germany" data-continent="Europe">
							<div className="name" title="Germany">
								<span className="alpha-2">DE </span>
								Germany
							</div>
							<div className="capital">Berlin</div>
							<img className="flag" src={de} alt="Germany Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Ghana" data-continent="Africa">
							<div className="name" title="Ghana">
								<span className="alpha-2">GH </span>
								Ghana
							</div>
							<div className="capital">Accra</div>
							<img className="flag" src={gh} alt="Ghana Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Gibraltar" data-continent="Europe">
							<div className="name" title="Gibraltar">
								<span className="alpha-2">GI </span>
								Gibraltar
							</div>
							<div className="capital">Gibraltar</div>
							<img className="flag" src={gi} alt="Gibraltar Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Greece" data-continent="Europe">
							<div className="name" title="Greece">
								<span className="alpha-2">GR </span>
								Greece
							</div>
							<div className="capital">Athens</div>
							<img className="flag" src={gr} alt="Greece Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Greenland" data-continent="North America">
							<div className="name" title="Greenland">
								<span className="alpha-2">GL </span>
								Greenland
							</div>
							<div className="capital">Nuuk</div>
							<img className="flag" src={gl} alt="Greenland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Grenada" data-continent="North America">
							<div className="name" title="Grenada">
								<span className="alpha-2">GD </span>
								Grenada
							</div>
							<div className="capital">St. George's</div>
							<img className="flag" src={gd} alt="Grenada Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Guadeloupe" data-continent="North America">
							<div className="name" title="Guadeloupe">
								<span className="alpha-2">GP </span>
								Guadeloupe
							</div>
							<div className="capital">Basse-Terre</div>
							<img className="flag" src={gp} alt="Guadeloupe Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Guam" data-continent="Oceania">
							<div className="name" title="Guam">
								<span className="alpha-2">GU </span>
								Guam
							</div>
							<div className="capital">Hagåtña</div>
							<img className="flag" src={gu} alt="Guam Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Guatemala" data-continent="North America">
							<div className="name" title="Guatemala">
								<span className="alpha-2">GT </span>
								Guatemala
							</div>
							<div className="capital">Guatemala City</div>
							<img className="flag" src={gt} alt="Guatemala Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Guernsey" data-continent="Europe">
							<div className="name" title="Guernsey">
								<span className="alpha-2">GG </span>
								Guernsey
							</div>
							<div className="capital">Saint Peter Port</div>
							<img className="flag" src={gg} alt="Guernsey Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Guinea" data-continent="Africa">
							<div className="name" title="Guinea">
								<span className="alpha-2">GN </span>
								Guinea
							</div>
							<div className="capital">Conakry</div>
							<img className="flag" src={gn} alt="Guinea Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Guinea-Bissau" data-continent="Africa">
							<div className="name" title="Guinea-Bissau">
								<span className="alpha-2">GW </span>
								Guinea-Bissau
							</div>
							<div className="capital">Bissau</div>
							<img className="flag" src={gw} alt="Guinea-Bissau Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Guyana" data-continent="South America">
							<div className="name" title="Guyana">
								<span className="alpha-2">GY </span>
								Guyana
							</div>
							<div className="capital">Georgetown</div>
							<img className="flag" src={gy} alt="Guyana Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Haiti" data-continent="North America">
							<div className="name" title="Haiti">
								<span className="alpha-2">HT </span>
								Haiti
							</div>
							<div className="capital">Port-au-Prince</div>
							<img className="flag" src={ht} alt="Haiti Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Holy See" data-continent="Europe">
							<div className="name" title="Holy See">
								<span className="alpha-2">VA </span>
								Holy See
							</div>
							<div className="capital">Vatican City</div>
							<img className="flag" src={va} alt="Holy See Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Honduras" data-continent="North America">
							<div className="name" title="Honduras">
								<span className="alpha-2">HN </span>
								Honduras
							</div>
							<div className="capital">Tegucigalpa</div>
							<img className="flag" src={hn} alt="Honduras Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Hong Kong" data-continent="Asia">
							<div className="name" title="Hong Kong">
								<span className="alpha-2">HK </span>
								Hong Kong
							</div>
							<div className="capital">Hong Kong</div>
							<img className="flag" src={hk} alt="Hong Kong Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Hungary" data-continent="Europe">
							<div className="name" title="Hungary">
								<span className="alpha-2">HU </span>
								Hungary
							</div>
							<div className="capital">Budapest</div>
							<img className="flag" src={hu} alt="Hungary Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Iceland" data-continent="Europe">
							<div className="name" title="Iceland">
								<span className="alpha-2">IS </span>
								Iceland
							</div>
							<div className="capital">Reykjavik</div>
							<img className="flag" src={is} alt="Iceland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="India" data-continent="Asia">
							<div className="name" title="India">
								<span className="alpha-2">IN </span>
								India
							</div>
							<div className="capital">New Delhi</div>
							<img className="flag" src={_in} alt="India Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Indonesia" data-continent="Asia">
							<div className="name" title="Indonesia">
								<span className="alpha-2">ID </span>
								Indonesia
							</div>
							<div className="capital">Jakarta</div>
							<img className="flag" src={id} alt="Indonesia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Iran (Islamic Republic of)" data-continent="Asia">
							<div className="name" title="Iran (Islamic Republic of)">
								<span className="alpha-2">IR </span>
								Iran (Islamic Republic of)
							</div>
							<div className="capital">Tehran</div>
							<img className="flag" src={ir} alt="Iran (Islamic Republic of) Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Iraq" data-continent="Asia">
							<div className="name" title="Iraq">
								<span className="alpha-2">IQ </span>
								Iraq
							</div>
							<div className="capital">Baghdad</div>
							<img className="flag" src={iq} alt="Iraq Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Ireland" data-continent="Europe">
							<div className="name" title="Ireland">
								<span className="alpha-2">IE </span>
								Ireland
							</div>
							<div className="capital">Dublin</div>
							<img className="flag" src={ie} alt="Ireland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Isle of Man" data-continent="Europe">
							<div className="name" title="Isle of Man">
								<span className="alpha-2">IM </span>
								Isle of Man
							</div>
							<div className="capital">Douglas</div>
							<img className="flag" src={im} alt="Isle of Man Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Israel" data-continent="Asia">
							<div className="name" title="Israel">
								<span className="alpha-2">IL </span>
								Israel
							</div>
							<div className="capital">Jerusalem</div>
							<img className="flag" src={il} alt="Israel Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Italy" data-continent="Europe">
							<div className="name" title="Italy">
								<span className="alpha-2">IT </span>
								Italy
							</div>
							<div className="capital">Rome</div>
							<img className="flag" src={it} alt="Italy Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Jamaica" data-continent="North America">
							<div className="name" title="Jamaica">
								<span className="alpha-2">JM </span>
								Jamaica
							</div>
							<div className="capital">Kingston</div>
							<img className="flag" src={jm} alt="Jamaica Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Japan" data-continent="Asia">
							<div className="name" title="Japan">
								<span className="alpha-2">JP </span>
								Japan
							</div>
							<div className="capital">Tokyo</div>
							<img className="flag" src={jp} alt="Japan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Jersey" data-continent="Europe">
							<div className="name" title="Jersey">
								<span className="alpha-2">JE </span>
								Jersey
							</div>
							<div className="capital">Saint Helier</div>
							<img className="flag" src={je} alt="Jersey Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Jordan" data-continent="Asia">
							<div className="name" title="Jordan">
								<span className="alpha-2">JO </span>
								Jordan
							</div>
							<div className="capital">Amman</div>
							<img className="flag" src={jo} alt="Jordan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Kazakhstan" data-continent="Asia">
							<div className="name" title="Kazakhstan">
								<span className="alpha-2">KZ </span>
								Kazakhstan
							</div>
							<div className="capital">Astana</div>
							<img className="flag" src={kz} alt="Kazakhstan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Kenya" data-continent="Africa">
							<div className="name" title="Kenya">
								<span className="alpha-2">KE </span>
								Kenya
							</div>
							<div className="capital">Nairobi</div>
							<img className="flag" src={ke} alt="Kenya Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Kiribati" data-continent="Oceania">
							<div className="name" title="Kiribati">
								<span className="alpha-2">KI </span>
								Kiribati
							</div>
							<div className="capital">South Tarawa</div>
							<img className="flag" src={ki} alt="Kiribati Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Kosovo" data-continent="Europe">
							<div className="name" title="Kosovo">
								<span className="alpha-2">XK </span>
								Kosovo
							</div>
							<div className="capital">Pristina</div>
							<img className="flag" src={xk} alt="Kosovo Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Kuwait" data-continent="Asia">
							<div className="name" title="Kuwait">
								<span className="alpha-2">KW </span>
								Kuwait
							</div>
							<div className="capital">Kuwait City</div>
							<img className="flag" src={kw} alt="Kuwait Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Kyrgyzstan" data-continent="Asia">
							<div className="name" title="Kyrgyzstan">
								<span className="alpha-2">KG </span>
								Kyrgyzstan
							</div>
							<div className="capital">Bishkek</div>
							<img className="flag" src={kg} alt="Kyrgyzstan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Laos" data-continent="Asia">
							<div className="name" title="Laos">
								<span className="alpha-2">LA </span>
								Laos
							</div>
							<div className="capital">Vientiane</div>
							<img className="flag" src={la} alt="Laos Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Latvia" data-continent="Europe">
							<div className="name" title="Latvia">
								<span className="alpha-2">LV </span>
								Latvia
							</div>
							<div className="capital">Riga</div>
							<img className="flag" src={lv} alt="Latvia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Lebanon" data-continent="Asia">
							<div className="name" title="Lebanon">
								<span className="alpha-2">LB </span>
								Lebanon
							</div>
							<div className="capital">Beirut</div>
							<img className="flag" src={lb} alt="Lebanon Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Lesotho" data-continent="Africa">
							<div className="name" title="Lesotho">
								<span className="alpha-2">LS </span>
								Lesotho
							</div>
							<div className="capital">Maseru</div>
							<img className="flag" src={ls} alt="Lesotho Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Liberia" data-continent="Africa">
							<div className="name" title="Liberia">
								<span className="alpha-2">LR </span>
								Liberia
							</div>
							<div className="capital">Monrovia</div>
							<img className="flag" src={lr} alt="Liberia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Libya" data-continent="Africa">
							<div className="name" title="Libya">
								<span className="alpha-2">LY </span>
								Libya
							</div>
							<div className="capital">Tripoli</div>
							<img className="flag" src={ly} alt="Libya Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Liechtenstein" data-continent="Europe">
							<div className="name" title="Liechtenstein">
								<span className="alpha-2">LI </span>
								Liechtenstein
							</div>
							<div className="capital">Vaduz</div>
							<img className="flag" src={li} alt="Liechtenstein Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Lithuania" data-continent="Europe">
							<div className="name" title="Lithuania">
								<span className="alpha-2">LT </span>
								Lithuania
							</div>
							<div className="capital">Vilnius</div>
							<img className="flag" src={lt} alt="Lithuania Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Luxembourg" data-continent="Europe">
							<div className="name" title="Luxembourg">
								<span className="alpha-2">LU </span>
								Luxembourg
							</div>
							<div className="capital">Luxembourg City</div>
							<img className="flag" src={lu} alt="Luxembourg Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Macau" data-continent="Asia">
							<div className="name" title="Macau">
								<span className="alpha-2">MO </span>
								Macau
							</div>
							<div className="capital">Macau</div>
							<img className="flag" src={mo} alt="Macau Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Madagascar" data-continent="Africa">
							<div className="name" title="Madagascar">
								<span className="alpha-2">MG </span>
								Madagascar
							</div>
							<div className="capital">Antananarivo</div>
							<img className="flag" src={mg} alt="Madagascar Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Malawi" data-continent="Africa">
							<div className="name" title="Malawi">
								<span className="alpha-2">MW </span>
								Malawi
							</div>
							<div className="capital">Lilongwe</div>
							<img className="flag" src={mw} alt="Malawi Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Malaysia" data-continent="Asia">
							<div className="name" title="Malaysia">
								<span className="alpha-2">MY </span>
								Malaysia
							</div>
							<div className="capital">Kuala Lumpur</div>
							<img className="flag" src={my} alt="Malaysia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Maldives" data-continent="Asia">
							<div className="name" title="Maldives">
								<span className="alpha-2">MV </span>
								Maldives
							</div>
							<div className="capital">Malé</div>
							<img className="flag" src={mv} alt="Maldives Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Mali" data-continent="Africa">
							<div className="name" title="Mali">
								<span className="alpha-2">ML </span>
								Mali
							</div>
							<div className="capital">Bamako</div>
							<img className="flag" src={ml} alt="Mali Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Malta" data-continent="Europe">
							<div className="name" title="Malta">
								<span className="alpha-2">MT </span>
								Malta
							</div>
							<div className="capital">Valletta</div>
							<img className="flag" src={mt} alt="Malta Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Marshall Islands" data-continent="Oceania">
							<div className="name" title="Marshall Islands">
								<span className="alpha-2">MH </span>
								Marshall Islands
							</div>
							<div className="capital">Majuro</div>
							<img className="flag" src={mh} alt="Marshall Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Martinique" data-continent="North America">
							<div className="name" title="Martinique">
								<span className="alpha-2">MQ </span>
								Martinique
							</div>
							<div className="capital">Fort-de-France</div>
							<img className="flag" src={mq} alt="Martinique Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Mauritania" data-continent="Africa">
							<div className="name" title="Mauritania">
								<span className="alpha-2">MR </span>
								Mauritania
							</div>
							<div className="capital">Nouakchott</div>
							<img className="flag" src={mr} alt="Mauritania Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Mauritius" data-continent="Africa">
							<div className="name" title="Mauritius">
								<span className="alpha-2">MU </span>
								Mauritius
							</div>
							<div className="capital">Port Louis</div>
							<img className="flag" src={mu} alt="Mauritius Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Mayotte" data-continent="Africa">
							<div className="name" title="Mayotte">
								<span className="alpha-2">YT </span>
								Mayotte
							</div>
							<div className="capital">Mamoudzou</div>
							<img className="flag" src={yt} alt="Mayotte Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Mexico" data-continent="North America">
							<div className="name" title="Mexico">
								<span className="alpha-2">MX </span>
								Mexico
							</div>
							<div className="capital">Mexico City</div>
							<img className="flag" src={mx} alt="Mexico Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Moldova" data-continent="Europe">
							<div className="name" title="Moldova">
								<span className="alpha-2">MD </span>
								Moldova
							</div>
							<div className="capital">Chișinău</div>
							<img className="flag" src={md} alt="Moldova Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Monaco" data-continent="Europe">
							<div className="name" title="Monaco">
								<span className="alpha-2">MC </span>
								Monaco
							</div>
							<div className="capital">Monaco</div>
							<img className="flag" src={mc} alt="Monaco Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Mongolia" data-continent="Asia">
							<div className="name" title="Mongolia">
								<span className="alpha-2">MN </span>
								Mongolia
							</div>
							<div className="capital">Ulaanbaatar</div>
							<img className="flag" src={mn} alt="Mongolia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Montenegro" data-continent="Europe">
							<div className="name" title="Montenegro">
								<span className="alpha-2">ME </span>
								Montenegro
							</div>
							<div className="capital">Podgorica</div>
							<img className="flag" src={me} alt="Montenegro Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Montserrat" data-continent="North America">
							<div className="name" title="Montserrat">
								<span className="alpha-2">MS </span>
								Montserrat
							</div>
							<div className="capital">Little Bay, Brades, Plymouth</div>
							<img className="flag" src={ms} alt="Montserrat Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Morocco" data-continent="Africa">
							<div className="name" title="Morocco">
								<span className="alpha-2">MA </span>
								Morocco
							</div>
							<div className="capital">Rabat</div>
							<img className="flag" src={ma} alt="Morocco Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Mozambique" data-continent="Africa">
							<div className="name" title="Mozambique">
								<span className="alpha-2">MZ </span>
								Mozambique
							</div>
							<div className="capital">Maputo</div>
							<img className="flag" src={mz} alt="Mozambique Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Myanmar" data-continent="Asia">
							<div className="name" title="Myanmar">
								<span className="alpha-2">MM </span>
								Myanmar
							</div>
							<div className="capital">Naypyidaw</div>
							<img className="flag" src={mm} alt="Myanmar Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Namibia" data-continent="Africa">
							<div className="name" title="Namibia">
								<span className="alpha-2">NA </span>
								Namibia
							</div>
							<div className="capital">Windhoek</div>
							<img className="flag" src={na} alt="Namibia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Nauru" data-continent="Oceania">
							<div className="name" title="Nauru">
								<span className="alpha-2">NR </span>
								Nauru
							</div>
							<div className="capital">Yaren District</div>
							<img className="flag" src={nr} alt="Nauru Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Nepal" data-continent="Asia">
							<div className="name" title="Nepal">
								<span className="alpha-2">NP </span>
								Nepal
							</div>
							<div className="capital">Kathmandu</div>
							<img className="flag" src={np} alt="Nepal Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Netherlands" data-continent="Europe">
							<div className="name" title="Netherlands">
								<span className="alpha-2">NL </span>
								Netherlands
							</div>
							<div className="capital">Amsterdam</div>
							<img className="flag" src={nl} alt="Netherlands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="New Caledonia" data-continent="Oceania">
							<div className="name" title="New Caledonia">
								<span className="alpha-2">NC </span>
								New Caledonia
							</div>
							<div className="capital">Nouméa</div>
							<img className="flag" src={nc} alt="New Caledonia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="New Zealand" data-continent="Oceania">
							<div className="name" title="New Zealand">
								<span className="alpha-2">NZ </span>
								New Zealand
							</div>
							<div className="capital">Wellington</div>
							<img className="flag" src={nz} alt="New Zealand Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Nicaragua" data-continent="North America">
							<div className="name" title="Nicaragua">
								<span className="alpha-2">NI </span>
								Nicaragua
							</div>
							<div className="capital">Managua</div>
							<img className="flag" src={ni} alt="Nicaragua Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Niger" data-continent="Africa">
							<div className="name" title="Niger">
								<span className="alpha-2">NE </span>
								Niger
							</div>
							<div className="capital">Niamey</div>
							<img className="flag" src={ne} alt="Niger Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Nigeria" data-continent="Africa">
							<div className="name" title="Nigeria">
								<span className="alpha-2">NG </span>
								Nigeria
							</div>
							<div className="capital">Abuja</div>
							<img className="flag" src={ng} alt="Nigeria Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Niue" data-continent="Oceania">
							<div className="name" title="Niue">
								<span className="alpha-2">NU </span>
								Niue
							</div>
							<div className="capital">Alofi</div>
							<img className="flag" src={nu} alt="Niue Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Norfolk Island" data-continent="Oceania">
							<div className="name" title="Norfolk Island">
								<span className="alpha-2">NF </span>
								Norfolk Island
							</div>
							<div className="capital">Kingston</div>
							<img className="flag" src={nf} alt="Norfolk Island Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="North Korea" data-continent="Asia">
							<div className="name" title="North Korea">
								<span className="alpha-2">KP </span>
								North Korea
							</div>
							<div className="capital">Pyongyang</div>
							<img className="flag" src={kp} alt="North Korea Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="North Macedonia" data-continent="Europe">
							<div className="name" title="North Macedonia">
								<span className="alpha-2">MK </span>
								North Macedonia
							</div>
							<div className="capital">Skopje</div>
							<img className="flag" src={mk} alt="North Macedonia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Northern Ireland" data-continent="Europe">
							<div className="name" title="Northern Ireland">
								<span className="alpha-2">GB -NIR</span>
								Northern Ireland
							</div>
							<div className="capital">Belfast</div>
							<img className="flag" src={gbNIR} alt="Northern Ireland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Northern Mariana Islands" data-continent="Oceania">
							<div className="name" title="Northern Mariana Islands">
								<span className="alpha-2">MP </span>
								Northern Mariana Islands
							</div>
							<div className="capital">Saipan</div>
							<img className="flag" src={mp} alt="Northern Mariana Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Norway" data-continent="Europe">
							<div className="name" title="Norway">
								<span className="alpha-2">NO </span>
								Norway
							</div>
							<div className="capital">Oslo</div>
							<img className="flag" src={no} alt="Norway Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Oman" data-continent="Asia">
							<div className="name" title="Oman">
								<span className="alpha-2">OM </span>
								Oman
							</div>
							<div className="capital">Muscat</div>
							<img className="flag" src={om} alt="Oman Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Pakistan" data-continent="Asia">
							<div className="name" title="Pakistan">
								<span className="alpha-2">PK </span>
								Pakistan
							</div>
							<div className="capital">Islamabad</div>
							<img className="flag" src={pk} alt="Pakistan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Palau" data-continent="Oceania">
							<div className="name" title="Palau">
								<span className="alpha-2">PW </span>
								Palau
							</div>
							<div className="capital">Ngerulmud</div>
							<img className="flag" src={pw} alt="Palau Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Panama" data-continent="North America">
							<div className="name" title="Panama">
								<span className="alpha-2">PA </span>
								Panama
							</div>
							<div className="capital">Panama City</div>
							<img className="flag" src={pa} alt="Panama Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Papua New Guinea" data-continent="Oceania">
							<div className="name" title="Papua New Guinea">
								<span className="alpha-2">PG </span>
								Papua New Guinea
							</div>
							<div className="capital">Port Moresby</div>
							<img className="flag" src={pg} alt="Papua New Guinea Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Paraguay" data-continent="South America">
							<div className="name" title="Paraguay">
								<span className="alpha-2">PY </span>
								Paraguay
							</div>
							<div className="capital">Asunción</div>
							<img className="flag" src={py} alt="Paraguay Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Peru" data-continent="South America">
							<div className="name" title="Peru">
								<span className="alpha-2">PE </span>
								Peru
							</div>
							<div className="capital">Lima</div>
							<img className="flag" src={pe} alt="Peru Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Philippines" data-continent="Asia">
							<div className="name" title="Philippines">
								<span className="alpha-2">PH </span>
								Philippines
							</div>
							<div className="capital">Manila</div>
							<img className="flag" src={ph} alt="Philippines Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Pitcairn" data-continent="Oceania">
							<div className="name" title="Pitcairn">
								<span className="alpha-2">PN </span>
								Pitcairn
							</div>
							<div className="capital">Adamstown</div>
							<img className="flag" src={pn} alt="Pitcairn Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Poland" data-continent="Europe">
							<div className="name" title="Poland">
								<span className="alpha-2">PL </span>
								Poland
							</div>
							<div className="capital">Warsaw</div>
							<img className="flag" src={pl} alt="Poland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Portugal" data-continent="Europe">
							<div className="name" title="Portugal">
								<span className="alpha-2">PT </span>
								Portugal
							</div>
							<div className="capital">Lisbon</div>
							<img className="flag" src={pt} alt="Portugal Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Puerto Rico" data-continent="North America">
							<div className="name" title="Puerto Rico">
								<span className="alpha-2">PR </span>
								Puerto Rico
							</div>
							<div className="capital">San Juan</div>
							<img className="flag" src={pr} alt="Puerto Rico Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Qatar" data-continent="Asia">
							<div className="name" title="Qatar">
								<span className="alpha-2">QA </span>
								Qatar
							</div>
							<div className="capital">Doha</div>
							<img className="flag" src={qa} alt="Qatar Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Republic of the Congo" data-continent="Africa">
							<div className="name" title="Republic of the Congo">
								<span className="alpha-2">CG </span>
								Republic of the Congo
							</div>
							<div className="capital">Brazzaville</div>
							<img className="flag" src={cg} alt="Republic of the Congo Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Romania" data-continent="Europe">
							<div className="name" title="Romania">
								<span className="alpha-2">RO </span>
								Romania
							</div>
							<div className="capital">Bucharest</div>
							<img className="flag" src={ro} alt="Romania Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Russia" data-continent="Europe">
							<div className="name" title="Russia">
								<span className="alpha-2">RU </span>
								Russia
							</div>
							<div className="capital">Moscow</div>
							<img className="flag" src={ru} alt="Russia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Rwanda" data-continent="Africa">
							<div className="name" title="Rwanda">
								<span className="alpha-2">RW </span>
								Rwanda
							</div>
							<div className="capital">Kigali</div>
							<img className="flag" src={rw} alt="Rwanda Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Réunion" data-continent="Africa">
							<div className="name" title="Réunion">
								<span className="alpha-2">RE </span>
								Réunion
							</div>
							<div className="capital">Saint-Denis</div>
							<img className="flag" src={re} alt="Réunion Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saint Barthélemy" data-continent="North America">
							<div className="name" title="Saint Barthélemy">
								<span className="alpha-2">BL </span>
								Saint Barthélemy
							</div>
							<div className="capital">Gustavia</div>
							<img className="flag" src={bl} alt="Saint Barthélemy Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saint Helena, Ascension and Tristan da Cunha" data-continent="Africa">
							<div className="name" title="Saint Helena, Ascension and Tristan da Cunha">
								<span className="alpha-2">SH </span>
								Saint Helena, Ascension and Tristan da Cunha
							</div>
							<div className="capital">Jamestown</div>
							<img className="flag" src={sh} alt="Saint Helena, Ascension and Tristan da Cunha Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saint Kitts and Nevis" data-continent="North America">
							<div className="name" title="Saint Kitts and Nevis">
								<span className="alpha-2">KN </span>
								Saint Kitts and Nevis
							</div>
							<div className="capital">Basseterre</div>
							<img className="flag" src={kn} alt="Saint Kitts and Nevis Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saint Lucia" data-continent="North America">
							<div className="name" title="Saint Lucia">
								<span className="alpha-2">LC </span>
								Saint Lucia
							</div>
							<div className="capital">Castries</div>
							<img className="flag" src={lc} alt="Saint Lucia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saint Martin" data-continent="North America">
							<div className="name" title="Saint Martin">
								<span className="alpha-2">MF </span>
								Saint Martin
							</div>
							<div className="capital">Marigot</div>
							<img className="flag" src={mf} alt="Saint Martin Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saint Pierre and Miquelon" data-continent="North America">
							<div className="name" title="Saint Pierre and Miquelon">
								<span className="alpha-2">PM </span>
								Saint Pierre and Miquelon
							</div>
							<div className="capital">Saint-Pierre</div>
							<img className="flag" src={pm} alt="Saint Pierre and Miquelon Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saint Vincent and the Grenadines" data-continent="North America">
							<div className="name" title="Saint Vincent and the Grenadines">
								<span className="alpha-2">VC </span>
								Saint Vincent and the Grenadines
							</div>
							<div className="capital">Kingstown</div>
							<img className="flag" src={vc} alt="Saint Vincent and the Grenadines Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Samoa" data-continent="Oceania">
							<div className="name" title="Samoa">
								<span className="alpha-2">WS </span>
								Samoa
							</div>
							<div className="capital">Apia</div>
							<img className="flag" src={ws} alt="Samoa Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="San Marino" data-continent="Europe">
							<div className="name" title="San Marino">
								<span className="alpha-2">SM </span>
								San Marino
							</div>
							<div className="capital">San Marino</div>
							<img className="flag" src={sm} alt="San Marino Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Sao Tome and Principe" data-continent="Africa">
							<div className="name" title="Sao Tome and Principe">
								<span className="alpha-2">ST </span>
								Sao Tome and Principe
							</div>
							<div className="capital">São Tomé</div>
							<img className="flag" src={st} alt="Sao Tome and Principe Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Saudi Arabia" data-continent="Asia">
							<div className="name" title="Saudi Arabia">
								<span className="alpha-2">SA </span>
								Saudi Arabia
							</div>
							<div className="capital">Riyadh</div>
							<img className="flag" src={sa} alt="Saudi Arabia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Scotland" data-continent="Europe">
							<div className="name" title="Scotland">
								<span className="alpha-2">GB -SCT</span>
								Scotland
							</div>
							<div className="capital">Edinburgh</div>
							<img className="flag" src={gbSCT} alt="Scotland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Senegal" data-continent="Africa">
							<div className="name" title="Senegal">
								<span className="alpha-2">SN </span>
								Senegal
							</div>
							<div className="capital">Dakar</div>
							<img className="flag" src={sn} alt="Senegal Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Serbia" data-continent="Europe">
							<div className="name" title="Serbia">
								<span className="alpha-2">RS </span>
								Serbia
							</div>
							<div className="capital">Belgrade</div>
							<img className="flag" src={rs} alt="Serbia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Seychelles" data-continent="Africa">
							<div className="name" title="Seychelles">
								<span className="alpha-2">SC </span>
								Seychelles
							</div>
							<div className="capital">Victoria</div>
							<img className="flag" src={sc} alt="Seychelles Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Sierra Leone" data-continent="Africa">
							<div className="name" title="Sierra Leone">
								<span className="alpha-2">SL </span>
								Sierra Leone
							</div>
							<div className="capital">Freetown</div>
							<img className="flag" src={sl} alt="Sierra Leone Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Singapore" data-continent="Asia">
							<div className="name" title="Singapore">
								<span className="alpha-2">SG </span>
								Singapore
							</div>
							<div className="capital">Singapore</div>
							<img className="flag" src={sg} alt="Singapore Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Sint Maarten" data-continent="North America">
							<div className="name" title="Sint Maarten">
								<span className="alpha-2">SX </span>
								Sint Maarten
							</div>
							<div className="capital">Philipsburg</div>
							<img className="flag" src={sx} alt="Sint Maarten Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Slovakia" data-continent="Europe">
							<div className="name" title="Slovakia">
								<span className="alpha-2">SK </span>
								Slovakia
							</div>
							<div className="capital">Bratislava</div>
							<img className="flag" src={sk} alt="Slovakia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Slovenia" data-continent="Europe">
							<div className="name" title="Slovenia">
								<span className="alpha-2">SI </span>
								Slovenia
							</div>
							<div className="capital">Ljubljana</div>
							<img className="flag" src={si} alt="Slovenia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Solomon Islands" data-continent="Oceania">
							<div className="name" title="Solomon Islands">
								<span className="alpha-2">SB </span>
								Solomon Islands
							</div>
							<div className="capital">Honiara</div>
							<img className="flag" src={sb} alt="Solomon Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Somalia" data-continent="Africa">
							<div className="name" title="Somalia">
								<span className="alpha-2">SO </span>
								Somalia
							</div>
							<div className="capital">Mogadishu</div>
							<img className="flag" src={so} alt="Somalia Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="South Africa" data-continent="Africa">
							<div className="name" title="South Africa">
								<span className="alpha-2">ZA </span>
								South Africa
							</div>
							<div className="capital">Pretoria</div>
							<img className="flag" src={za} alt="South Africa Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="South Georgia and the South Sandwich Islands" data-continent="Antarctica">
							<div className="name" title="South Georgia and the South Sandwich Islands">
								<span className="alpha-2">GS </span>
								South Georgia and the South Sandwich Islands
							</div>
							<div className="capital">King Edward Point</div>
							<img className="flag" src={gs} alt="South Georgia and the South Sandwich Islands Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="South Korea" data-continent="Asia">
							<div className="name" title="South Korea">
								<span className="alpha-2">KR </span>
								South Korea
							</div>
							<div className="capital">Seoul</div>
							<img className="flag" src={kr} alt="South Korea Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="South Sudan" data-continent="Africa">
							<div className="name" title="South Sudan">
								<span className="alpha-2">SS </span>
								South Sudan
							</div>
							<div className="capital">Juba</div>
							<img className="flag" src={ss} alt="South Sudan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Spain" data-continent="Europe">
							<div className="name" title="Spain">
								<span className="alpha-2">ES </span>
								Spain
							</div>
							<div className="capital">Madrid</div>
							<img className="flag" src={es} alt="Spain Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Sri Lanka" data-continent="Asia">
							<div className="name" title="Sri Lanka">
								<span className="alpha-2">LK </span>
								Sri Lanka
							</div>
							<div className="capital">Sri Jayawardenepura Kotte, Colombo</div>
							<img className="flag" src={lk} alt="Sri Lanka Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="State of Palestine" data-continent="Asia">
							<div className="name" title="State of Palestine">
								<span className="alpha-2">PS </span>
								State of Palestine
							</div>
							<div className="capital">Ramallah</div>
							<img className="flag" src={ps} alt="State of Palestine Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Sudan" data-continent="Africa">
							<div className="name" title="Sudan">
								<span className="alpha-2">SD </span>
								Sudan
							</div>
							<div className="capital">Khartoum</div>
							<img className="flag" src={sd} alt="Sudan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Suriname" data-continent="South America">
							<div className="name" title="Suriname">
								<span className="alpha-2">SR </span>
								Suriname
							</div>
							<div className="capital">Paramaribo</div>
							<img className="flag" src={sr} alt="Suriname Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Svalbard and Jan Mayen" data-continent="Europe">
							<div className="name" title="Svalbard and Jan Mayen">
								<span className="alpha-2">SJ </span>
								Svalbard and Jan Mayen
							</div>
							<div className="capital">Longyearbyen</div>
							<img className="flag" src={sj} alt="Svalbard and Jan Mayen Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Swaziland" data-continent="Africa">
							<div className="name" title="Swaziland">
								<span className="alpha-2">SZ </span>
								Swaziland
							</div>
							<div className="capital">Lobamba, Mbabane</div>
							<img className="flag" src={sz} alt="Swaziland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Sweden" data-continent="Europe">
							<div className="name" title="Sweden">
								<span className="alpha-2">SE </span>
								Sweden
							</div>
							<div className="capital">Stockholm</div>
							<img className="flag" src={se} alt="Sweden Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Switzerland" data-continent="Europe">
							<div className="name" title="Switzerland">
								<span className="alpha-2">CH </span>
								Switzerland
							</div>
							<div className="capital">Bern</div>
							<img className="flag" src={ch} alt="Switzerland Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Syrian Arab Republic" data-continent="Asia">
							<div className="name" title="Syrian Arab Republic">
								<span className="alpha-2">SY </span>
								Syrian Arab Republic
							</div>
							<div className="capital">Damascus</div>
							<img className="flag" src={sy} alt="Syrian Arab Republic Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Taiwan" data-continent="Asia">
							<div className="name" title="Taiwan">
								<span className="alpha-2">TW </span>
								Taiwan
							</div>
							<div className="capital">Taipei</div>
							<img className="flag" src={tw} alt="Taiwan Flag">
						</img></div>
					</div>
					<div className="col">
						<div className="country" data-name="Tajikistan" data-continent="Asia">
							<div className="name" title="Tajikistan">
								<span className="alpha-2">TJ </span>
								Tajikistan
							</div>
							<div className="capital">Dushanbe</div>
							<img className="flag" src={tj} alt="Tajikistan Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Tanzania" data-continent="Africa">
							<div className="name" title="Tanzania">
								<span className="alpha-2">TZ </span>
								Tanzania
							</div>
							<div className="capital">Dodoma</div>
							<img className="flag" src={tz} alt="Tanzania Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Thailand" data-continent="Asia">
							<div className="name" title="Thailand">
								<span className="alpha-2">TH </span>
								Thailand
							</div>
							<div className="capital">Bangkok</div>
							<img className="flag" src={th} alt="Thailand Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Timor-Leste" data-continent="Asia">
							<div className="name" title="Timor-Leste">
								<span className="alpha-2">TL </span>
								Timor-Leste
							</div>
							<div className="capital">Dili</div>
							<img className="flag" src={tl} alt="Timor-Leste Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Togo" data-continent="Africa">
							<div className="name" title="Togo">
								<span className="alpha-2">TG </span>
								Togo
							</div>
							<div className="capital">Lomé</div>
							<img className="flag" src={tg} alt="Togo Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Tokelau" data-continent="Oceania">
							<div className="name" title="Tokelau">
								<span className="alpha-2">TK </span>
								Tokelau
							</div>
							<div className="capital">Nukunonu, Atafu,Tokelau</div>
							<img className="flag" src={tk} alt="Tokelau Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Tonga" data-continent="Oceania">
							<div className="name" title="Tonga">
								<span className="alpha-2">TO </span>
								Tonga
							</div>
							<div className="capital">Nukuʻalofa</div>
							<img className="flag" src={to} alt="Tonga Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Trinidad and Tobago" data-continent="South America">
							<div className="name" title="Trinidad and Tobago">
								<span className="alpha-2">TT </span>
								Trinidad and Tobago
							</div>
							<div className="capital">Port of Spain</div>
							<img className="flag" src={tt} alt="Trinidad and Tobago Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Tunisia" data-continent="Africa">
							<div className="name" title="Tunisia">
								<span className="alpha-2">TN </span>
								Tunisia
							</div>
							<div className="capital">Tunis</div>
							<img className="flag" src={tn} alt="Tunisia Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Turkey" data-continent="Asia">
							<div className="name" title="Turkey">
								<span className="alpha-2">TR </span>
								Turkey
							</div>
							<div className="capital">Ankara</div>
							<img className="flag" src={tr} alt="Turkey Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Turkmenistan" data-continent="Asia">
							<div className="name" title="Turkmenistan">
								<span className="alpha-2">TM </span>
								Turkmenistan
							</div>
							<div className="capital">Ashgabat</div>
							<img className="flag" src={tm} alt="Turkmenistan Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Turks and Caicos Islands" data-continent="North America">
							<div className="name" title="Turks and Caicos Islands">
								<span className="alpha-2">TC </span>
								Turks and Caicos Islands
							</div>
							<div className="capital">Cockburn Town</div>
							<img className="flag" src={tc} alt="Turks and Caicos Islands Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Tuvalu" data-continent="Oceania">
							<div className="name" title="Tuvalu">
								<span className="alpha-2">TV </span>
								Tuvalu
							</div>
							<div className="capital">Funafuti</div>
							<img className="flag" src={tv} alt="Tuvalu Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Uganda" data-continent="Africa">
							<div className="name" title="Uganda">
								<span className="alpha-2">UG </span>
								Uganda
							</div>
							<div className="capital">Kampala</div>
							<img className="flag" src={ug} alt="Uganda Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Ukraine" data-continent="Europe">
							<div className="name" title="Ukraine">
								<span className="alpha-2">UA </span>
								Ukraine
							</div>
							<div className="capital">Kiev</div>
							<img className="flag" src={ua} alt="Ukraine Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="United Arab Emirates" data-continent="Asia">
							<div className="name" title="United Arab Emirates">
								<span className="alpha-2">AE </span>
								United Arab Emirates
							</div>
							<div className="capital">Abu Dhabi</div>
							<img className="flag" src={ae} alt="United Arab Emirates Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="United Kingdom" data-continent="Europe">
							<div className="name" title="United Kingdom">
								<span className="alpha-2">GB </span>
								United Kingdom
							</div>
							<div className="capital">London</div>
							<img className="flag" src={gb} alt="United Kingdom Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="United States Minor Outlying Islands" data-continent="North America">
							<div className="name" title="United States Minor Outlying Islands">
								<span className="alpha-2">UM </span>
								United States Minor Outlying Islands
							</div>
							<div className="capital">Washington, D.C.</div>
							<img className="flag" src={um} alt="United States Minor Outlying Islands Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="United States of America" data-continent="North America">
							<div className="name" title="United States of America">
								<span className="alpha-2">US </span>
								United States of America
							</div>
							<div className="capital">Washington, D.C.</div>
							<img className="flag" src={us} alt="United States of America Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Uruguay" data-continent="South America">
							<div className="name" title="Uruguay">
								<span className="alpha-2">UY </span>
								Uruguay
							</div>
							<div className="capital">Montevideo</div>
							<img className="flag" src={uy} alt="Uruguay Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Uzbekistan" data-continent="Asia">
							<div className="name" title="Uzbekistan">
								<span className="alpha-2">UZ </span>
								Uzbekistan
							</div>
							<div className="capital">Tashkent</div>
							<img className="flag" src={uz} alt="Uzbekistan Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Vanuatu" data-continent="Oceania">
							<div className="name" title="Vanuatu">
								<span className="alpha-2">VU </span>
								Vanuatu
							</div>
							<div className="capital">Port Vila</div>
							<img className="flag" src={vu} alt="Vanuatu Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Venezuela (Bolivarian Republic of)" data-continent="South America">
							<div className="name" title="Venezuela (Bolivarian Republic of)">
								<span className="alpha-2">VE </span>
								Venezuela (Bolivarian Republic of)
							</div>
							<div className="capital">Caracas</div>
							<img className="flag" src={ve} alt="Venezuela (Bolivarian Republic of) Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Vietnam" data-continent="Asia">
							<div className="name" title="Vietnam">
								<span className="alpha-2">VN </span>
								Vietnam
							</div>
							<div className="capital">Hanoi</div>
							<img className="flag" src={vn} alt="Vietnam Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Virgin Islands (British)" data-continent="North America">
							<div className="name" title="Virgin Islands (British)">
								<span className="alpha-2">VG </span>
								Virgin Islands (British)
							</div>
							<div className="capital">Road Town</div>
							<img className="flag" src={vg} alt="Virgin Islands (British) Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Virgin Islands (U.S.)" data-continent="North America">
							<div className="name" title="Virgin Islands (U.S.)">
								<span className="alpha-2">VI </span>
								Virgin Islands (U.S.)
							</div>
							<div className="capital">Charlotte Amalie</div>
							<img className="flag" src={vi} alt="Virgin Islands (U.S.) Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Wales" data-continent="Europe">
							<div className="name" title="Wales">
								<span className="alpha-2">GB -WLS</span>
								Wales
							</div>
							<div className="capital">Cardiff</div>
							<img className="flag" src={gbWls} alt="Wales Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Wallis and Futuna" data-continent="Oceania">
							<div className="name" title="Wallis and Futuna">
								<span className="alpha-2">WF </span>
								Wallis and Futuna
							</div>
							<div className="capital">Mata-Utu</div>
							<img className="flag" src={wf} alt="Wallis and Futuna Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Western Sahara" data-continent="Africa">
							<div className="name" title="Western Sahara">
								<span className="alpha-2">EH </span>
								Western Sahara
							</div>
							<div className="capital">Laayoune</div>
							<img className="flag" src={eh} alt="Western Sahara Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Yemen" data-continent="Asia">
							<div className="name" title="Yemen">
								<span className="alpha-2">YE </span>
								Yemen
							</div>
							<div className="capital">Sana'a</div>
							<img className="flag" src={ye} alt="Yemen Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Zambia" data-continent="Africa">
							<div className="name" title="Zambia">
								<span className="alpha-2">ZM </span>
								Zambia
							</div>
							<div className="capital">Lusaka</div>
							<img className="flag" src={zm} alt="Zambia Flag"/>
						</div>
					</div>
					<div className="col">
						<div className="country" data-name="Zimbabwe" data-continent="Africa">
							<div className="name" title="Zimbabwe">
								<span className="alpha-2">ZW </span>
								Zimbabwe
							</div>
							<div className="capital">Harare</div>
							<img className="flag" src={zw} alt="Zimbabwe Flag"/>
						</div>
					</div>
				</div> 
				{/* <!--[ .row end ]--> */}
			</div>
		</div>
    )
}

export default CountryFlagsIcon