export const hotelsData = [
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 12v.01"></path>
            <path d="M3 21h18"></path>
            <path d="M6 21v-16a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v16"></path>
        </svg>,
        title:'Room Book',
        text:'Description text here...',
        path:'room-booking',
        theme_color:'var(--theme-color1)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 12v.01"></path>
            <path d="M3 21h18"></path>
            <path d="M6 21v-16a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v16"></path>
        </svg>,
        title:'Room List',
        text:'Description text here...',
        path:'room-list',
        theme_color:'var(--theme-color2)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 12v.01"></path>
            <path d="M3 21h18"></path>
            <path d="M6 21v-16a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v16"></path>
        </svg>,
        title:'Room Status',
        text:'Description text here...',
        path:'room-status',
        theme_color:'var(--theme-color3)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" className="mb-3"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 8h.01"></path><path d="M11.5 21h-5.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v7"></path><path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l4 4"></path><path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l.5 .5"></path><path d="M15 19l2 2l4 -4"></path></svg>,
        title:'Room Image',
        text:'Description text here...',
        path:'room-image',
        theme_color:'var(--theme-color4)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M7 3m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z"></path>
            <path d="M12 3v2"></path>
            <path d="M10 15v.01"></path>
            <path d="M10 18v.01"></path>
            <path d="M14 18v.01"></path>
            <path d="M14 15v.01"></path>
        </svg>,
        title:'Room Facilites',
        text:'Description text here...',
        path:'room-facilites-list',
        theme_color:'var(--theme-color2)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8.5 10a1.5 1.5 0 0 1 -1.5 -1.5a5.5 5.5 0 0 1 11 0v10.5a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2c0 -1.38 .71 -2.444 1.88 -3.175l4.424 -2.765c1.055 -.66 1.696 -1.316 1.696 -2.56a2.5 2.5 0 1 0 -5 0a1.5 1.5 0 0 1 -1.5 1.5z"></path>
        </svg>,
        title:'House keeping',
        text:'Description text here...',
        path:'housekeeping-assign-room',
        theme_color:'var(--theme-color3)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5"></path>
        </svg>,
        title:'Cab Booking',
        text:'Description text here...',
        path:'cab-booking',
        theme_color:'var(--theme-color5)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5"></path>
        </svg>,
        title:'Cab List',
        text:'Description text here...',
        path:'cab-list',
        theme_color:'var(--theme-color2)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M13 5h8"></path>
            <path d="M13 9h5"></path>
            <path d="M13 15h8"></path>
            <path d="M13 19h5"></path>
            <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
            <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
        </svg>,
        title:'Items Manage',
        text:'Description text here...',
        path:'items-list',
        theme_color:'var(--theme-color6)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 8m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0"></path>
            <path d="M8 11a5 5 0 1 0 3.998 1.997"></path>
            <path d="M12.002 19.003a5 5 0 1 0 3.998 -8.003"></path>
        </svg>,
        title:'Personalised',
        text:'Description text here...',
        path:'personalised',
        theme_color:'var(--theme-color4)'
    }, 
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 8v4.172a2 2 0 0 0 .586 1.414l5.71 5.71a2.41 2.41 0 0 0 3.408 0l3.592 -3.592a2.41 2.41 0 0 0 0 -3.408l-5.71 -5.71a2 2 0 0 0 -1.414 -.586h-4.172a2 2 0 0 0 -2 2z" />
            <path d="M18 19l1.592 -1.592a4.82 4.82 0 0 0 0 -6.816l-4.592 -4.592" />
            <path d="M7 10h-.01" />
        </svg>,
        title:'Promocode',
        text:'Description text here...',
        path:'promocode-list',
        theme_color:'var(--theme-color2)'
    },
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'Booking Report',
        text:'Description text here...',
        path:'booking-report',
        theme_color:'var(--theme-color1)'
    },   
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'Purchase Report',
        text:'Description text here...',
        path:'purchase-report',
        theme_color:'var(--theme-color3)'
    },   
    {
        icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="24" viewBox="0 0 24 24"  stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
            <path d="M18 14v4h4" />
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
            <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M8 11h4" />
            <path d="M8 15h3" />
        </svg>,
        title:'Stock Report',
        text:'Description text here...',
        path:'stock-report',
        theme_color:'var(--theme-color5)'
    },   
    
]